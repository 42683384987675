import { IDocumentsState } from "@/store/types/state";

export const getDefaultState = (): IDocumentsState => {
  return {
    documents:null,
    document: null
  };
};

export const state: IDocumentsState = getDefaultState();

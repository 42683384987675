import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import authHeader from "./helpers/authHeader";
import { ICreateReaction } from "@/types/Reaction";
export default class ReactionService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/reactions";

  public async create(reaction: ICreateReaction): Promise <Boolean> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}/`,
      data:reaction,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async delete(postId: string): Promise <Boolean> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${postId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

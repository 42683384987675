<template>
  <nav v-motion :initial="{opacity:0, y:100}" :enter="{opacity:1, y:0}" :class="`nav ${isOpen ? 'h-screen' : ''}`">
    <div class="nav__container">
      <div class="nav__brand">
        <router-link :to="{ name: 'Home' }">
          <img
            :src="require('@/assets/images/logo.png')"
            alt=""
            class="nav__brand-logo"
          />
        </router-link>
        <atom-icon
          class="nav__brand-hamburger"
          :icon="icon"
          @click="isOpen = !isOpen"
        ></atom-icon>
      </div>
      <ul class="nav__list">
        <li class="nav__list-item">
          <router-link :to="{ name: 'Home' }" class="nav__list-item-link"
            >Accueil</router-link
          >
        </li>
        <li class="nav__list-item">
          <router-link :to="{ name: 'Explore' }" class="nav__list-item-link"
            >A propos</router-link
          >
        </li>
        <li class="nav__list-item">
          <router-link :to="{ name: 'Coalition' }" class="nav__list-item-link"
            >Coalition</router-link
          >
        </li>
        <li class="nav__list-item">
          <router-link
            :to="{ name: 'DataAndTools' }"
            class="nav__list-item-link"
            >Digitech
          </router-link>
        </li>
        <li class="nav__list-item">
          <router-link :to="{ name: 'Contact' }" class="nav__list-item-link"
            >Contact</router-link
          >
        </li>
      </ul>
      <ul class="nav__cta">
        <!-- <li class="nav__list-item nav__list-item--optional">
          <router-link :to="{name:'Contact'}" class="nav__list-item-link nav__list-item-link">
            Contact
          </router-link>
        </li>
        <li class="nav__list-item nav__list-item--optional">
          <router-link :to="{name:'Newsletter'}" class="nav__list-item-link"> Newsletter </router-link>
        </li> -->
        <li class="nav__cta-item ml-5">
          <atom-button
            :to="{ name: 'ForumHome' }"
            class="nav__cta-item-link"
            type="link"
            variant="primary"
          >
            E-Forum
          </atom-button>
        </li>
      </ul>
      <!-- Mobile styles -->
      <ul :class="`nav__list-mobile ${!isOpen ? 'hidden' : ''}`">
        <li class="nav__list-mobile-item">
          <router-link
            :to="{ name: 'Explore' }"
            class="nav__list-mobile-item-link"
          >
            Explorer E-tachawor
          </router-link>
        </li>
        <li class="nav__list-mobile-item">
          <router-link
            :to="{ name: 'Coalition' }"
            class="nav__list-mobile-item-link"
          >
            Coalition
          </router-link>
        </li>
        <li class="nav__list-mobile-item">
          <router-link
            :to="{ name: 'About' }"
            class="nav__list-mobile-item-link"
          >
            A propos
          </router-link>
        </li>
        <li class="nav__list-mobile-item">
          <router-link
            :to="{ name: 'DataAndTools' }"
            class="nav__list-mobile-item-link"
          >
            Digitech
          </router-link>
        </li>
        <li class="nav__list-mobile-item">
          <router-link
            :to="{ name: 'Contact' }"
            class="nav__list-mobile-item-link"
          >
            Contact
          </router-link>
        </li>
      </ul>
      <ul :class="`nav__cta-mobile ${!isOpen ? 'hidden' : ''}`">
        <li class="nav__cta-mobile-item">
          <atom-button
            class="nav__cta-mobile-item-link"
            type="link"
            variant="light-bg"
            >Plaidoyer</atom-button
          >
        </li>
        <li class="nav__cta-mobile-item">
          <atom-button
            class="nav__cta-mobile-item-link"
            type="link"
            :to="{ name: 'ForumHome' }"
            variant="primary"
            >E-Forum</atom-button
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { useAuth } from "@/composables/auth.ts";
import { ref, computed } from "vue";

const { loggedIn, currentUser } = useAuth();
const isOpen = ref(false);
const isDropDownOpen = ref(false);
const icon = computed(() => {
  return !isOpen.value ? "menu" : "close";
});
</script>

<style lang="scss">
.nav {
  @apply w-full justify-between items-center bg-white shadow-sm relative z-10 py-5;
    md:h-auto;
  &__container {
    @apply container mx-auto px-4 
      lg:flex lg:items-center;
  }
  &__brand {
    @apply flex-1 flex justify-between;
    &-logo {
      @apply w-48;
    }
    &-hamburger {
      @apply w-4 cursor-pointer 
        lg:hidden;
    }
  }
  &__list {
    @apply hidden lg:block mr-3;
    &-item {
      @apply inline-block mx-3 font-medium relative py-2 hover:after:w-[70%];

      &:after {
        @apply 
          bg-primary w-[0%] h-[3px] block
          absolute bottom-0 left-0
          content-['']
          transition-all ease-in duration-300
          ;
      }

      &--optional {
        @apply lg:hidden xl:inline-block;
      }
    }
    &-submenu{
      @apply absolute rounded-lg bg-white top-[105px] shadow-sm;
    }
    &-subitem{
      @apply block py-3 px-8 cursor-pointer hover:bg-gray-50 rounded-lg;
    }
  }
  &__cta {
    @apply hidden lg:flex items-center;
    &-item {
      &-link {
        @apply text-sm py-1 px-4 text-center font-medium shadow-md;
      }
    }
  }
  // Mobile styles
  &__list-mobile {
    @apply mt-9 lg:hidden;
    &-item {
      &-link {
        @apply text-lg py-2 inline-block font-medium;
      }
    }
  }
  &__cta-mobile {
    @apply mt-7 lg:hidden;
    &-item {
      &-link {
        @apply text-lg py-2 w-60 text-center inline-block font-medium rounded-md shadow-md;
        &:first-of-type {
          @apply mb-5;
        }
      }
    }
  }
  &__hamburger-icon {
    @apply w-10;
  }
}
</style>

import DocumentsService from "@/services/documents.service";
import { communitiesMutationTypes, documentsMutationTypes } from "@/store/types/mutations";
import { ICommunitiesState, IDocumentsState, IRootState } from "@/store/types/state";
import { ICommunity, ICommunityID, ICreateCommunity, IUpdateCommunity } from "@/types/Community";
import { ICreateDocument, IDocument, IUpdateDocument } from "@/types/Document";
import { IResult } from "@/types/Result";
import { ActionContext } from "vuex";
const documentsService = new DocumentsService();

export const actions = {
    getAll: async (
      { commit }: ActionContext<IDocumentsState,IRootState>,
      {
        page=1,
        limit=10,
        filter=''
      }:{page:number,limit:number,filter:string}
    ): Promise<Boolean> => {
      return documentsService.getAll({page,filter,limit}).then(({data, meta}:IResult) => {
        commit(documentsMutationTypes.SET_DOCUMENTS, {data, meta});
        return Promise.resolve(true);
      });
    },
    getById: async (
      { commit }: ActionContext<IDocumentsState,IRootState>,
      documentId:string
    ): Promise<IDocument> => {
      return documentsService.getById(documentId).then((document:IDocument) => {
        commit(documentsMutationTypes.SET_DOCUMENT, document);
        return Promise.resolve(document);
      });
    },
    create: async (
      { commit }: ActionContext<IDocumentsState,IRootState>,
      document:ICreateDocument
    ): Promise<IDocument> => {
      return documentsService.create(document).then((document:IDocument) => {
        commit(documentsMutationTypes.PUSH_DOCUMENT, document);
        return document;
      });
    },
    update: async (
      { commit }: ActionContext<IDocumentsState,IRootState>,
      updatedDocument:IUpdateDocument
    ): Promise<IDocument> => {
      return documentsService.update(updatedDocument).then((document:IDocument) => {
        commit(documentsMutationTypes.UPDATE_DOCUMENT, document);
        return document;
      });
    },
    delete: async (
      { commit }: ActionContext<IDocumentsState,IRootState>,
      documentId:string
    ): Promise<string> => {
      return documentsService.delete(documentId).then((documentId:string) => {
        commit(documentsMutationTypes.DELETE_DOCUMENT, documentId);
        return documentId;
      });
    }
};

import { IUsersState } from "@/store/types/state";
import { usersMutationTypes } from "@/store/types/mutations";
import { IRegisterUser, IResultUsers, IUser } from "@/types/User";

export const mutations = {
  [usersMutationTypes.SET_USERS](state: IUsersState, users: IResultUsers) {
    state.users = users;
  },
  [usersMutationTypes.SET_USER](state: IUsersState, user: IUser) {
    state.user = user;
  },
  [usersMutationTypes.SET_NEW_USER](state: IUsersState, user: IRegisterUser) {
    state.newUser = user;
  },
  [usersMutationTypes.PUSH_USER](state: IUsersState, user: IUser) {
    if(!state.users?.data?.find( (elm:IUser) => elm.id == user.id)){
      state.users = Object.assign((state.users as IResultUsers), {data:[...(state.users?.data as IUser[]), user]});
    }
  },
  [usersMutationTypes.UPDATE_USER](state: IUsersState, user: IUser) {
    if(state.users?.data?.find( (elm:IUser) => elm.id == user.id)){
      state.users = Object.assign((state.users as IResultUsers), {data:(state.users?.data as IUser[]).map( elm => {
        if(elm.id == user.id){
          Object.assign(elm, user);
        }
        return elm;
      })});
    }
  },
  [usersMutationTypes.DELETE_USER](state: IUsersState, userId: string) {
    state.users = Object.assign((state.users as IResultUsers), {data:state.users?.data.filter(elm => elm.id != userId)});
  },
};

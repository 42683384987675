import {  IMessagesState } from "@/store/types/state";

export const getDefaultState = (): IMessagesState => {
  return {
    users: [],
    messages: [],
    isChatOpen: false,
    activeUser: null,
    notificationsCount: 0,
    notificationsMessages: []
  };
};

export const state: IMessagesState = getDefaultState();

<template>
  <block-topbar />
  <block-navbar v-if="showNavbar"/>
  <router-view />
  <block-footer />
  <block-copyright />
</template>

<script setup>
import BlockTopbar from "@/components/blocks/BlockTopbar.vue";
import BlockNavbar from "@/components/blocks/BlockNavbar.vue";
import BlockFooter from "@/components/blocks/BlockFooter.vue";
import BlockCopyright from "@/components/blocks/BlockCopyright.vue";
import { ref, watch } from 'vue';
import {useRoute} from "vue-router";

const route = useRoute();
const showNavbar = ref(route.meta.hasOwnProperty('navbar') ? false : true);
watch(() => route, (newVal) => {
  const meta = newVal.meta;
  if(meta.hasOwnProperty('navbar')){
    showNavbar.value = false;
  }
  else{
    showNavbar.value = true;
  }
},{ deep: true});
</script>

<style lang="scss" scoped></style>

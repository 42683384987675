<script setup>
  
</script>

<template>
  <div class="copyright">
    <div class="copyright__container">
      <p class="copyright__text">
        © Copyright 2023. All Rights Reserved, Proudly Created By 
        <a href="https://3clabcom.com/" target="_blank">
          3CLabcom
        </a>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.copyright{
  @apply bg-primary py-4;
  &__container{
    @apply container text-center;
  }
  &__text{
    @apply text-sm xl:text-base text-white font-medium;
    a{
      @apply underline;
    }
  }
}
</style>
<template>
  <div v-motion :initial="{opacity:0, y:-100}" :enter="{opacity:1, y:0}" class="topbar">
    <div class="topbar__container">
      <div class="topbar__contact">
        <div class="topbar__contact-item">
          <atom-icon class="topbar__contact-icon" icon="phone" :fill="true"></atom-icon>
          <a href="tel:0537778001" class="topbar__contact-text">05 37 77 80 01</a>
        </div>
        <div class="topbar__contact-item">
          <atom-icon class="topbar__contact-icon" icon="mail" :fill="true"></atom-icon>
            <a href="mailto:contact@etachawor.com" class="topbar__contact-text">contact@etachawor.com</a>
        </div>
      </div>
      <div class="topbar__socials">
        <a href="https://www.instagram.com/sancusmaroc/" target="_blank">
          <atom-icon class="topbar__socials-icon" icon="instagram" :fill="true"></atom-icon>
        </a>
        <a href="https://web.facebook.com/profile.php?id=100087460140047" target="_blank">
          <atom-icon class="topbar__socials-icon" icon="facebook" :fill="true"></atom-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
  .topbar{
    @apply bg-primary py-1 flex;
    &__container{
      @apply container mx-auto px-4 flex flex-col justify-between
      sm:flex-row;
    }
    &__socials{
      @apply hidden sm:flex gap-2;
      &-icon{
        @apply fill-[white] h-5 w-5 transition-all hover:scale-110;
      }
    }
    &__contact{
      @apply flex flex-col gap-3 mb-3
      sm:flex-row sm:mb-0;
      &-item{
        @apply flex items-center gap-1;
      }
      &-text{
        @apply font-normal text-white;
      }
      &-icon{
        @apply fill-[white] h-5 w-5;
      }
    }
  }
</style>
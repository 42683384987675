import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import authHeader from "@/services/helpers/authHeader";
import { IAdvocacy, ICreateAdvocacy, IResultAdvocacies, IUpdateAdvocacy } from "@/types/Advocacy";
export default class AdvocaciesService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/advocacies";

  public async getAll(page:number): Promise <IResultAdvocacies> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/all?limit=5&page=${page}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async create(advocacy:ICreateAdvocacy): Promise <IAdvocacy> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}`,
      data:advocacy,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async update(updatedAdvocacy:{payload:IUpdateAdvocacy, id:string}): Promise <IAdvocacy> {
    const {payload, id} = updatedAdvocacy;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${id}`,
      data:payload,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async delete(id:string): Promise <IAdvocacy> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${id}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

import { INewsState } from "@/store/types/state";
import { newsMutationTypes } from "@/store/types/mutations";
import { IArticle, IResultArticles } from "@/types/New";

export const mutations = {
  [newsMutationTypes.SET_ARTICLES](state:INewsState, articles:IResultArticles){
    state.news = articles;
  },
  [newsMutationTypes.SET_ADVOCACIES](state:INewsState, advocacies:IResultArticles){
    state.publicAdvocacies = advocacies;
  },
  [newsMutationTypes.PUSH_ARTICLE](state:INewsState, article:IArticle){
    if(!state.news?.data?.find(elm => elm.id == article.id)){
      state.news = Object.assign(state.news, {data:[article, ...(state.news?.data as IArticle[])]});
    }
  },
  [newsMutationTypes.PUSH_ADVOCACY](state:INewsState, article:IArticle){
    if(!state.publicAdvocacies?.data?.find(elm => elm.id == article.id)){
      state.publicAdvocacies = Object.assign(state.publicAdvocacies, {data:[article, ...(state.publicAdvocacies?.data as IArticle[])]});
    }
  },
  [newsMutationTypes.UPDATE_ARTICLE](state: INewsState, updatedArticle:IArticle) {
    state.news = Object.assign({data:(state.news?.data as IArticle[]).map( (article:IArticle) => {
      if(article.id == updatedArticle.id){
        Object.assign(article, updatedArticle);
      }
      return article;
    })});
  },
  [newsMutationTypes.UPDATE_ADVOCACY](state: INewsState, updatedArticle:IArticle) {
    state.publicAdvocacies = Object.assign({data:(state.publicAdvocacies?.data as IArticle[]).map( (article:IArticle) => {
      if(article.id == updatedArticle.id){
        Object.assign(article, updatedArticle);
      }
      return article;
    })});
  },
  [newsMutationTypes.DELETE_ARTICLE](state: INewsState, articleId: string) {
    state.news = Object.assign
    ((state.news as IResultArticles), {data:(state.news?.data as IArticle[]).filter( elm => elm.id !== articleId)});
  },
  [newsMutationTypes.DELETE_ADVOCACY](state: INewsState, articleId: string) {
    state.publicAdvocacies = Object.assign
    ((state.publicAdvocacies as IResultArticles), {data:(state.publicAdvocacies?.data as IArticle[]).filter( elm => elm.id !== articleId)});
  }
};

export const authMutationTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_LOGGED_IN: "SET_LOGGED_IN",
  RESET_STATE: "RESET_STATE"
};

export const usersMutationTypes = {
  SET_USERS: "SET_USERS",
  SET_NEW_USER: "SET_NEW_USER",
  PUSH_USER: "PUSH_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  SET_USER: "SET_USER"
}
export const communitiesMutationTypes = {
  SET_COMMUNITIES: "SET_COMMUNITIES",
  SET_COMMUNITY: "SET_COMMUNITY",
  SET_USER_COMMUNITIES: "SET_USER_COMMUNITIES",
  PUSH_COMMUNITY: "PUSH_COMMUNITY",
  UPDATE_COMMUNITY: "UPDATE_COMMUNITY",
  DELETE_COMMUNITY: "DELETE_COMMUNITY",
  SET_CURRENT_COMMUNITY_NAME: "SET_CURRENT_COMMUNITY_NAME"
}
export const postsMutationTypes = {
  SET_POSTS: "SET_POSTS",
  SET_USER_POSTS: "SET_USER_POSTS",
  PUSH_POST: "PUSH_POST",
  UPDATE_POST: "UPDATE_POST",
  DELETE_POST: "DELETE_POST",
  PUSH_COMMENT: "PUSH_COMMENT",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  SET_COMMENTS: "SET_COMMENTS",
  SET_COMMENTS_COUNT: "SET_COMMENTS_COUNT",
  UPDATE_REACTION: "UPDATE_REACTION",
  SET_LOADING: "SET_LOADING"
}
export const newsMutationTypes = {
  SET_ARTICLES: "SET_ARTICLES",
  SET_ADVOCACIES: "SET_ADVOCACIES",
  PUSH_ARTICLE: "PUSH_ARTICLE",
  PUSH_ADVOCACY: "PUSH_ADVOCACY",
  UPDATE_ARTICLE: "UPDATE_ARTICLE",
  UPDATE_ADVOCACY: "UPDATE_ADVOCACY",
  DELETE_ARTICLE: "DELETE_ARTICLE",
  DELETE_ADVOCACY: "DELETE_ADVOCACY"
}
export const filesMutationTypes = {
  SET_FILES: "SET_FILES",
}
export const messagesMutationTypes = {
  SET_MESSAGES: "SET_MESSAGES",
  SET_CHAT_USERS: "SET_CHAT_USERS",
  PUSH_MESSAGE: "PUSH_MESSAGE",
  SET_OPEN_STATUS: "SET_OPEN_STATUS",
  SET_ACTIVE_USER: "SET_ACTIVE_USER",
  PUSH_USER: "PUSH_USER",
  INC_NOTIFICATIONS_COUNT: "INC_NOTIFICATIONS_COUNT",
  SET_NOTIFICATIONS_COUNT: "SET_NOTIFICATIONS_COUNT",
  PUSH_NOTIFICATION_MESSAGE: "PUSH_NOTIFICATION_MESSAGE",
  SET_NOTIFICATIONS_MESSAGES: "SET_NOTIFICATIONS_MESSAGES"
}
export const notificationsMutationTypes = {
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  CHANGE_NOTIFICATIONS_COUNT: "CHANGE_NOTIFICATIONS_COUNT",
  SET_NOTIFICATIONS_COUNT: "SET_NOTIFICATIONS_COUNT",
  UPDATE_READ_STATUS: "UPDATE_READ_STATUS"
}
export const advocaciesMutationTypes = {
  SET_ADVOCACIES: "SET_ADVOCACIES",
  PUSH_ADVOCACY: "PUSH_ADVOCACY",
  DELETE_ADVOCACY: "DELETE_ADVOCACY"
}
export const phasesMutationTypes = {
  SET_PHASES: "SET_PHASES",
  SET_PHASE: "SET_PHASE",
  PUSH_PHASE: "PUSH_PHASE",
  DELETE_PHASE: "DELETE_PHASE"
}
export const activitiesMutationTypes = {
  SET_ACTIVITY_LOGS: "SET_ACTIVITY_LOGS",
  SET_STATS:"SET_STATS"
}
export const documentsMutationTypes = {
  SET_DOCUMENTS: "SET_DOCUMENTS",
  SET_DOCUMENT: "SET_DOCUMENT",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  PUSH_DOCUMENT: "PUSH_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT"
}
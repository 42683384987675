<template>
  <footer class="footer">
    <div class="footer__container">
      <span class="footer__brand-logo">E-Tachawor</span>
      <ul class="footer__navigation">
        <li class="footer__navigation-item">
          <router-link
            :to="{ name: 'Home' }"
            class="footer__navigation-item-link"
            >Accueil
          </router-link>
        </li>
        <li class="footer__navigation-item">
          <router-link
            :to="{ name: 'Explore' }"
            class="footer__navigation-item-link"
            >A propos
          </router-link>
        </li>
        <li class="footer__navigation-item">
          <router-link :to="{name:'Coalition'}" class="footer__navigation-item-link">Coalition</router-link>
        </li>
        <li class="footer__navigation-item">
          <router-link :to="{name:'DataAndTools'}" class="footer__navigation-item-link">Digitech</router-link>
        </li>
        <li class="footer__navigation-item">
          <router-link :to="{name:'Contact'}" class="footer__navigation-item-link">Contact</router-link>
        </li>
      </ul>
      <div class="footer__buttons">
        <atom-button 
          :to="{name:'ForumHome'}"
          class="footer__buttons-item" 
          type="link" 
          variant="primary"
          >E-Forum</atom-button
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  @apply bg-dark pt-9 pb-15;
  &__container {
    @apply container mx-auto px-4 flex flex-col 
      md:text-center md:items-center 
      lg:text-left lg:items-start lg:flex-row lg:justify-between gap-y-5;
  }
  &__brand-logo {
    @apply font-bold text-white text-lg;
  }
  &__navigation {
    &-item {
      &-link {
        @apply text-white py-1 inline-block;
      }
    }
  }
  &__buttons {
    &-item {
      @apply py-1 px-10;
    }
  }
}
</style>

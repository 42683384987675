import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import { IRegisterUser, IUpdateUser, IUser, ResetPasswordDto } from "@/types/User";
import authHeader from "./helpers/authHeader";
import { IResult } from "@/types/Result";

export default class UserService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/users";
  public async getAll(page:number): Promise<IResult> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}?limit=5&page=${page}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async getById(userId:string): Promise<IUser> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/${userId}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async getByStatus(status:string): Promise<IUser[]> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/status/${status}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async create(user:IRegisterUser): Promise<IUser> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"POST",
        url:`${this._BASE_API_URL}`,
        data:user,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async update(user:IUpdateUser): Promise<IUser> {
    const {id, ...userToUpdate} = user;
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"PATCH",
        url:`${this._BASE_API_URL}/${user.id}`,
        data:userToUpdate,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async delete(userId:string): Promise<IUser> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"DELETE",
        url:`${this._BASE_API_URL}/${userId}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async requestResetPassword(email:string): Promise<Boolean> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/reset-password/user/?email=${email}`,
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(true);
    });
  }
  public async resetPassword(payload:ResetPasswordDto): Promise<Boolean> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"POST",
        url:`${this._BASE_API_URL}/reset-password/`,
        data:payload
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(true);
    });
  }
}

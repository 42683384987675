import CommunitiesService from "@/services/communities.service";
import { communitiesMutationTypes } from "@/store/types/mutations";
import { ICommunitiesState, IRootState } from "@/store/types/state";
import { ICommunity, ICommunityID, ICreateCommunity, IUpdateCommunity } from "@/types/Community";
import { IResult } from "@/types/Result";
import { ActionContext } from "vuex";
const communitiesService = new CommunitiesService();

export const actions = {
  /*
    Get community by name
  */
    getByName: async (
      { rootState, commit }: ActionContext<ICommunitiesState,IRootState>,
      communityName?:string
    ): Promise<ICommunity> => {
      const paramCommunityName = communityName ? communityName:rootState.communities.currentCommunityName;
      return communitiesService.getByName(paramCommunityName).then((community:ICommunity) => {
        commit(communitiesMutationTypes.SET_COMMUNITY, community)
        return Promise.resolve(community);
      });
    },
    getUserCommunities: async (
      { commit, rootState }: ActionContext<ICommunitiesState,IRootState>,
    ): Promise<Boolean> => {
      const role = rootState.auth.currentUser?.role;
      if(role == 'admin'){
        return communitiesService.getAll().then(({data}:IResult) => {
          commit(communitiesMutationTypes.SET_USER_COMMUNITIES, data);
          return Promise.resolve(true);
        });
      }
      return communitiesService.getUserCommunities().then((communities:ICommunity[]) => {
        commit(communitiesMutationTypes.SET_USER_COMMUNITIES, communities);
        return Promise.resolve(true);
      });
    },
    getAll: async (
      { commit }: ActionContext<ICommunitiesState,IRootState>,
    ): Promise<Boolean> => {
      return communitiesService.getAll().then(({data}:IResult) => {
        commit(communitiesMutationTypes.SET_COMMUNITIES, data);
        return Promise.resolve(true);
      });
    },
    create: async (
      { commit }: ActionContext<ICommunitiesState,IRootState>,
      community:ICreateCommunity
    ): Promise<ICommunity> => {
      return communitiesService.create(community).then((community:ICommunity) => {
        commit(communitiesMutationTypes.PUSH_COMMUNITY, community);
        return community;
      });
    },
    update: async (
      { commit }: ActionContext<ICommunitiesState,IRootState>,
      updatedCommunity:IUpdateCommunity
    ): Promise<ICommunity> => {
      return communitiesService.update(updatedCommunity).then((community:ICommunity) => {
        commit(communitiesMutationTypes.UPDATE_COMMUNITY, community);
        return community;
      });
    },
    delete: async (
      { commit }: ActionContext<ICommunitiesState,IRootState>,
      communityId:string
    ): Promise<ICommunity> => {
      return communitiesService.delete(communityId).then((community:ICommunity) => {
        commit(communitiesMutationTypes.DELETE_COMMUNITY, communityId);
        return community;
      });
    },
    addMember: async (
      { commit }: ActionContext<ICommunitiesState,IRootState>,
      payload:{
        userId:string;
        communityId:string;
      }
    ): Promise<ICommunity> => {
      return communitiesService.addMember(payload).then((community:ICommunity) => {
        commit(communitiesMutationTypes.UPDATE_COMMUNITY, community);
        return community;
      });
    },
};

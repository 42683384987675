import { INotificationsState } from "@/store/types/state";
import { notificationsMutationTypes } from "@/store/types/mutations";
import { INotification, IResultNotifications } from "@/types/Notification";

export const mutations = {
  [notificationsMutationTypes.SET_NOTIFICATIONS](state: INotificationsState, notifications: IResultNotifications) {
    state.notifications = notifications;
  },
  [notificationsMutationTypes.PUSH_NOTIFICATION](state: INotificationsState, notification: INotification) {
    if(!state.notifications) state.notifications = {data:[]}
    state.notifications = {...state.notifications, data:[notification, ...(state.notifications?.data as INotification[])]} as IResultNotifications;
  },
  [notificationsMutationTypes.SET_NOTIFICATIONS_COUNT](state: INotificationsState,count: number){
    state.unreadNotificationsCount = count;
  },
  [notificationsMutationTypes.CHANGE_NOTIFICATIONS_COUNT](state: INotificationsState,isInc: Boolean){
    isInc ? state.unreadNotificationsCount += 1 : state.unreadNotificationsCount -= 1;
  },
  [notificationsMutationTypes.UPDATE_READ_STATUS](state: INotificationsState,notification: INotification){
    state.notifications = Object.assign((state.notifications as IResultNotifications), {data:state.notifications?.data.map(elm => {
      if(elm.id == notification.id){
        elm.read = true;
      }
      return elm;
    })});

  },
};

import { IAuthState } from "@/store/types/state";
import { ILoggedUser } from "@/types/User";
import { authMutationTypes } from "@/store/types/mutations";

export const mutations = {
  [authMutationTypes.SET_CURRENT_USER](state: IAuthState, user: ILoggedUser | null) {
    state.currentUser = user;
  },
  [authMutationTypes.SET_LOGGED_IN](state: IAuthState, loggedIn: boolean) {
    state.loggedIn = loggedIn;
  }
};

import { AxiosRequestConfig } from "axios";
import { BaseService } from "./base.service";
import axios from "axios";
import authHeader from "./helpers/authHeader";
import { INotification, IResultNotifications } from "@/types/Notification";

export default class NotificationsService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/notifications";
  public async getNotifications(page:number): Promise<IResultNotifications> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/?page=${page}&limit=10`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async getUnreadNotifications(): Promise<IResultNotifications> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/?filter.read=false`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async updateReadStatus(notificationId:string): Promise<INotification> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"PATCH",
        url:`${this._BASE_API_URL}/${notificationId}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
}

import { IConfigParameters } from "@/types/APICallConfig";
import { AxiosRequestConfig } from "axios";
export class BaseService {
  public getConfig({
    method,
    url,
    data={},
    headers={},
  }:IConfigParameters): AxiosRequestConfig {
    return {
      method: method,
      url,
      data,
      headers: {
        "Content-Type": "application/json",
        ...headers
      }
    };
  }
}

<template>
  <router-link :to="route" :class="`link ${className('link')}`">
    <slot />
  </router-link>
</template>

<script>
import classNameMixin from "@/mixins/classNameMixin.js";
export default {
  mixins: [classNameMixin],
  props: {
    variant: {
      type: String,
      default: "primary"
    },
    routeName: {
      type: String
    },
    routePath: {
      type: String
    }
  },
  computed: {
    route() {
      if (this.routeName && this.routeName.length) {
        return `{name:${this.routeName}}`;
      } else if (this.routePath && this.routePath.length) {
        return `{name:${this.routePath}}`;
      }
      return "/";
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  @apply inline-block font-medium;
  &--primary {
    @apply text-primary;
  }
  &--blue {
    @apply text-blue;
  }
  &--black {
    @apply text-black;
  }
  &--light {
    @apply text-[#D8D7D6];
  }
  &--gray {
    @apply text-black/80;
  }
  &--xs {
    @apply text-xs;
  }
  &--sm {
    @apply text-sm;
  }
  &--md {
    @apply text-base;
  }
  &--lg {
    @apply text-lg;
  }
  &--underline {
    @apply underline;
  }
  &--medium {
    @apply font-medium;
  }
  &--bold {
  }
}
</style>

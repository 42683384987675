import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const context = require.context("./modules", true, /index.ts/);
const modules: any = {};

// Auto import modules
context.keys().forEach(file => {
  // create the module name from file
  const moduleName = file.replace(/(\.\/|\/index\.ts$)/g, "");
  // register file context under module name
  modules[moduleName] = context(file).default || context(file);
  // override namespaced option
  modules[moduleName].namespaced = true;
});

export default createStore({
  modules,
  plugins: [createPersistedState({
    paths:['auth'],
    storage: window.localStorage
  })],

});

<template>
  <router-view/>
  <molecule-chat-notifications-container />
</template>

<script setup>
import {computed, provide, ref, watch} from "vue";
import {useStore} from "vuex";
import BlockUnderConstruction from "@/components/blocks/BlockUderConstruction.vue";
import MoleculeChatNotificationsContainer from "@/components/molecules/MoleculeChatNotificationContainer.vue";
const password = ref('');
const newMessagesCount = computed( () => state.messages.notificationsCount);
const newNotificationsCount = computed( () => state.notifications.unreadNotificationsCount);
const {state} = useStore();

watch(newNotificationsCount, (newVal, oldVal) => { setDocumentTitle(); });
watch(newMessagesCount, (newVal, oldVal) => { setDocumentTitle(); });

const isValidHttpUrl = (string) => {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
provide('imageUrl', (path) => {
    return path ? (isValidHttpUrl(path) ? path : process.env.VUE_APP_ASSETS_BASE + path):require('@/assets/images/thumbnail.png');
});

const isForumSection = computed(() => router.currentRoute.value.path.includes('/forum'));

const setDocumentTitle = () => {
  const notificationsCount = newNotificationsCount.value + newMessagesCount.value;

  if (notificationsCount) {
    document.title = `(${notificationsCount}) notifications en attente | E-Tachawor`;
  } else {
    document.title = "E-Tachawor";
  }
}
</script>

<style lang="scss">
  // Importing fonts  
  @import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400;0,6..96,500;0,6..96,600;0,6..96,700;0,6..96,800;0,6..96,900;1,6..96,400;1,6..96,500;1,6..96,600;1,6..96,700;1,6..96,800;1,6..96,900&display=swap');
body {
  overflow-x: hidden;
  background:#F9F8F9;
  div#app {
    min-height: 100vh;
  }
}

@media (max-width: 1024px) {
  .chat-notification-container {
    visibility: hidden;
  }
}
</style>

import {  IDocumentsState } from "@/store/types/state";
import { documentsMutationTypes } from "@/store/types/mutations";
import { IDocument, IResultDocuments } from "@/types/Document";

export const mutations = {
  [documentsMutationTypes.SET_DOCUMENTS](state: IDocumentsState, documents: IResultDocuments) {
    state.documents = documents;
  },
  [documentsMutationTypes.PUSH_DOCUMENT](state: IDocumentsState, document: IDocument) {
    if(!state.documents?.data?.find( (elm:IDocument) => elm.id == document.id)){
      state.documents = Object.assign((state.documents as IResultDocuments), {data:[...(state.documents?.data as IDocument[]), document]});
    }  },
  [documentsMutationTypes.SET_DOCUMENT](state: IDocumentsState, document: IDocument) {
    state.document = document;
  },
  [documentsMutationTypes.UPDATE_DOCUMENT](state: IDocumentsState, document: IDocument) {
    if(state.documents?.data?.find( (elm:IDocument) => elm.id == document.id)){
      state.documents = Object.assign((state.documents as IResultDocuments), {data:(state.documents?.data as IDocument[]).map( elm => {
        if(elm.id == document.id){
          Object.assign(elm, document);
        }
        return elm;
      })});
    }  
  },
  [documentsMutationTypes.DELETE_DOCUMENT](state: IDocumentsState, documentId: string) {
    state.documents = Object.assign((state.documents as IResultDocuments), {data:state.documents?.data.filter((elm:IDocument) => elm.id != documentId)});
  },
};

import { IAuthState } from "@/store/types/state";

export const getDefaultState = (): IAuthState => {
  return {
    loggedIn: false,
    currentUser: null
  };
};

export const state: IAuthState = getDefaultState();

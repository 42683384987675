import PostsService from "@/services/posts.service";
import ReactionsService from "@/services/reactions.service";

import { IComment, ICreateComment, ICreatePost, IPost, IUpdatePost } from "@/types/Post";
const postsService = new PostsService();
const reactionsService = new ReactionsService();

import { postsMutationTypes } from "@/store/types/mutations";
import { IResult } from "@/types/Result";
import { ICreateReaction } from "@/types/Reaction";
import { ActionContext } from "vuex";
import { IPostsState, IRootState } from "@/store/types/state";
export const actions = {
  createPost: async (
    { rootState, commit }: ActionContext<IPostsState,IRootState>,
    newPost: ICreatePost
  ): Promise<IPost> => {
    return postsService.create(newPost).then((post:IPost) => {
      const communityName = rootState.communities.currentCommunityName;
      commit(postsMutationTypes.PUSH_POST, {post, communityName});
      return Promise.resolve(post);
    });
  },
  updatePost: async (
    { rootState, commit }: ActionContext<IPostsState,IRootState>,
    newPost: IUpdatePost
  ): Promise<IPost> => {
    return postsService.update(newPost).then((post:IPost) => {
      const communityName = rootState.communities.currentCommunityName;
      commit(postsMutationTypes.UPDATE_POST, {updatedPost:post, communityName});
      return post;
    });
  },
  getByCommunityName: async (
    { rootState, state, commit }: ActionContext<IPostsState,IRootState>,
  ): Promise<Boolean> => {
    const communityName = rootState.communities.currentCommunityName;
    const communityPosts = state.posts.find(elm => elm.communityName == communityName);
    const page = communityPosts?.meta ? communityPosts.meta.currentPage + 1:1;
    const totalPages = communityPosts?.meta ? communityPosts.meta?.totalPages:1;
    if(page <= totalPages){
      return postsService.getByCommunityName(communityName, page).then(({data,meta}:IResult) => {
        commit(postsMutationTypes.SET_POSTS, {communityName,posts:data, meta:meta});
        return Promise.resolve(true);
      });
    }
    return Promise.resolve(false);
  },
  getById: async (
    { commit, rootState }: ActionContext<IPostsState,IRootState>,
    postId: string
  ): Promise<Boolean> => {
    const communityName = rootState.communities.currentCommunityName;
    return postsService.getById(postId).then((post:IPost) => {
      commit(postsMutationTypes.PUSH_POST, {post,communityName});
      return Promise.resolve(true);
    });
  },
  getByUser: async (
    {commit}:ActionContext<IPostsState, IRootState>,
    userId: string
  ): Promise<Boolean> => {
    return postsService.getByUser(userId).then(({data, meta}:IResult) => {
      commit(postsMutationTypes.SET_USER_POSTS, {posts:data, meta:meta});
      return Promise.resolve(true);
    });
  },
  deletePost: async (
    {commit, rootState}:ActionContext<IPostsState, IRootState>,
    postId: string
  ):Promise<IPost> => {
    return postsService.delete(postId).then((post:IPost) => {
      const communityName = rootState.communities.currentCommunityName;
      commit(postsMutationTypes.DELETE_POST, {postId, communityName});
      return Promise.resolve(post);
    });
  },
  createComment: async (
    { commit, rootState }: ActionContext<IPostsState,IRootState>,
    comment: ICreateComment
  ): Promise<Boolean> => {
    return postsService.createComment(comment).then((com:IComment) => {
      const communityName = rootState.communities.currentCommunityName;
      commit(postsMutationTypes.PUSH_COMMENT, {...com,parent:comment.parent});
      commit(postsMutationTypes.SET_COMMENTS_COUNT,{postId:comment.parent,communityName, mode:'inc'});
      return Promise.resolve(true);
    });
  },
  getComments: async (
    { state, commit }: ActionContext<IPostsState,IRootState>,
    payload:{postId: string, push:Boolean}
  ): Promise<Boolean> => {
    const {postId, push} = payload;
    const page = state.comments?.meta ? state.comments.meta.currentPage + 1:1;
    const totalPages = state.comments?.meta ? state.comments.meta.totalPages:1;
    if(page <= totalPages){
      return postsService.getComments(postId, page).then(({data,meta}:IResult) => {
        commit(postsMutationTypes.SET_COMMENTS, {comments:{data,meta},push});
        return Promise.resolve(true);
      });
    }
    return Promise.resolve(false);
  },
  deleteComment: async (
    {commit, rootState}:ActionContext<IPostsState, IRootState>,
    commentId: string
  ):Promise<IComment> => {
    return postsService.delete(commentId).then((comment:IComment) => {
      commit(postsMutationTypes.DELETE_COMMENT, commentId);
      return Promise.resolve(comment);
    });
  },
  createReaction: async (
    { rootState, commit }: ActionContext<IPostsState,IRootState>,
    reaction: ICreateReaction
  ): Promise<Boolean> => {
    const communityName = rootState.communities.currentCommunityName;
    commit(postsMutationTypes.UPDATE_REACTION, {reaction,communityName});
    return reactionsService.create(reaction).then(() => {
      return Promise.resolve(true);
    });
  },
  deleteReaction: async (
    { rootState, commit }: ActionContext<IPostsState,IRootState>,
    reaction: ICreateReaction
  ): Promise<Boolean> => {
    const communityName = rootState.communities.currentCommunityName;
    commit(postsMutationTypes.UPDATE_REACTION, {reaction,communityName,remove:true});
    return reactionsService.delete(reaction.content).then(() => {
      return Promise.resolve(true);
    });
  },
};

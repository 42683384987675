import { IUser, ILoginUser, IRegisterUser, ILoggedUser } from "@/types/User";
import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";

export default class AuthService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/auth";
  /**
   *
   * @param user The user getting registered
   * @returns A Promise that resolves to the created user
   */
  public async register(user: IRegisterUser): Promise<IUser> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"POST",
        url:`${this._BASE_API_URL}/register`,
        data:user
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  /**
   *
   * @param user The user being logged in
   * @returns A Promise that resolves to the logged in user
   */
  public async login(user: ILoginUser): Promise<ILoggedUser> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"POST",
        url:`${this._BASE_API_URL}/login`,
        data:user
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async verifyEmailAtomicity(email: string): Promise <Boolean> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"POST",
        url:`${this._BASE_API_URL}/check-email`,
        data:{email}
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

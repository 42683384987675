import { IUsersState } from "@/store/types/state";

export const getDefaultState = (): IUsersState => {
  return {
    users:null,
    newUser:null,
    user:null
  };
};

export const state: IUsersState = getDefaultState();

import { IUsersState } from "@/store/types/state";
import { IUser } from "@/types/User";

export const getters = {
  newUser(state:IUsersState){
    return state.newUser;
  },
  getById : (state:IUsersState) => (userId:string) => {
    return state.users?.data.find((user:IUser) => user.id == userId);
  },
};

<template>
  <router-link v-if="type == 'link'" :to="{name:'Home'}" :class="`btn ${className('btn')}`">
    <slot />
  </router-link>
  <button v-else :class="`btn ${className('btn')}`">
    <slot />
  </button>
</template>

<script>
import classNameMixin from "@/mixins/classNameMixin.js";
export default {
  mixins: [classNameMixin],
  props: {
    type: {
      type: String,
      default: "button"
    },
    variant: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  @apply rounded-md inline-block py-1 px-3;
  &--full {
    @apply w-full flex justify-center items-center;
  }
  &--primary {
    @apply bg-primary text-white hover:brightness-105;
  }
  &--success {
    @apply bg-green-600 text-white
    hover:bg-green-700;
  }
  &--danger {
    @apply bg-red-600 text-white
    hover:bg-red-700;
  }
  &--secondary {
    @apply bg-gray-200 text-primary hover:brightness-105;
  }
  &--light-bg {
    @apply bg-light text-primary hover:brightness-105;
  }
  &--gray-outline {
    @apply text-base border border-[#D1D5DB] text-[#6B7280];
  }
  &--primary-outline {
    @apply text-xs border border-primary text-primary font-medium;
  }
  &--white-outline {
    @apply text-xs border border-white text-white font-medium;
  }
  &--dark-outline {
    @apply text-base border border-dark text-dark font-medium;
  }
  &--red-outline {
    @apply text-base text-[#FF3A33] font-medium border border-[#FF3A33];
  }
  &--disabled {
    @apply bg-[#D8D7D6] text-white font-medium;
  }
  &--centered {
    @apply flex mx-auto text-center;
  }
  &--md {
    @apply py-[10px] px-5 text-base font-medium;
  }
  &--lg {
    @apply py-3 px-5 text-base font-medium;
  }
}
</style>

import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import { ICommunity, ICommunityID, ICreateCommunity, IUpdateCommunity } from "@/types/Community";
import authHeader from "@/services/helpers/authHeader";
import { IResult } from "@/types/Result";
export default class CommunitiesService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/communities";

  public async getByName(name: string): Promise <ICommunity> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/name/${name}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getUserCommunities(): Promise <ICommunity[]> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/all`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getAll(): Promise <IResult> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/?filter.public=false`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async create(community:ICreateCommunity): Promise <ICommunity> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}/`,
      data:community,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async update(community:IUpdateCommunity): Promise <ICommunity> {
    const {id, ...updatedCommunity} = community;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${id}`,
      data:updatedCommunity,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async delete(communityId:string): Promise <ICommunity> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${communityId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async addMember(payload:{userId:string;communityId:string}): Promise <ICommunity> {
    const {userId, communityId} = payload;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${communityId}/add`,
      data:{id:userId},
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

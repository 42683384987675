<template>
  <div class="chat-notification-item" @click="itemClicked">
    <div class="chat-notification-item__header">
        <div class="chat-notification-item__header__user-data">
            <img v-if="item.avatar" :src="imageUrl(item.avatar)" class="chat-notification-item__header__user-data__avatar" alt="">
            <div v-else class="chat-notification-item__header__user-data__avatar">
                {{item.firstname[0] + item.lastname[0]}}
            </div>
            <span class="chat-notification-item__header__user-data__full-name">
                {{ `${item.firstname} ${item.lastname}` }}
            </span>
        </div>
        <div class="chat-notification-item__header__meta-data">
            <span>message</span>
            <atom-icon
                icon="close"
                class="chat-notification-item__header__meta-data__close-action"
                @click.prevent="closeItem"
            >
      </atom-icon>
        </div>
    </div>
    <div class="chat-notification-item__body">
        {{ item.message.length > 100 ? `${item.message.slice(0,100)} ...` : item.message }}
    </div>
</div>
</template>

<script setup>
// Imports
import { toRefs, inject } from 'vue';

const imageUrl = inject('imageUrl');

// Props
const props = defineProps({
  item:{
    type: Object,
    required: true
  }
});
const { item } = toRefs(props);

// Emits
const emit = defineEmits(['closeItem', 'itemClicked'])

// Methods
const closeItem = (e) => {
    e.stopPropagation();
    emit('closeItem', item.value.id);
}

const itemClicked = () => {
    emit('itemClicked', item.value.id);
}
</script>

<style lang="scss" scoped>
.chat-notification-item {
    @apply
        px-2 py-3
        flex flex-col gap-2
        bg-white
        rounded-md
        shadow-sm
        cursor-pointer
        border-[1px]
        transition-all
        hover:border-2 hover:border-primary hover:shadow-md
    ;
    &__header {
        @apply flex justify-between items-center;
        &__user-data {
            @apply flex items-center gap-1;
            &__avatar {
                @apply w-6 h-6 bg-primary rounded-full flex justify-center items-center text-white text-sm uppercase font-bold;
            }
            &__full-name {
                @apply text-base uppercase font-bold;
            }
        }
        &__meta-data {
            @apply
                flex items-center
                text-base text-gray-300
            ;

            &__close-action {
                @apply
                    w-4 h-4
                    fill-[#6b7280]
                    transition-all
                    hover:fill-[#000]
                ;
            }
        }
    }
    &__body {
        @apply
            text-base text-gray-300
            break-all
        ;
    }
}
</style>
export default {
  methods: {
    className(base) {
      return this.variant
        .split(" ")
        .map(variant => `${base}--${variant}`)
        .join(" ");
    }
  }
};

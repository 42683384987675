import {  INotificationsState } from "@/store/types/state";

export const getDefaultState = (): INotificationsState => {
  return {
    notifications:null,
    unreadNotificationsCount:0,
  };
};

export const state: INotificationsState = getDefaultState();

import { IActivityState } from "@/store/types/state";
import { activitiesMutationTypes } from "@/store/types/mutations";
import { IDomainStats, IResultLogs } from "@/types/Activity";

export const mutations = {
  [activitiesMutationTypes.SET_ACTIVITY_LOGS](state: IActivityState, activities: IResultLogs) {
    state.activityLogs = activities;
  },
  [activitiesMutationTypes.SET_STATS](state: IActivityState, domainStats: IDomainStats) {
    const index = state.stats.findIndex(elm => elm.domain == domainStats.domain);
    if( index >= 0){
      state.stats[index] = domainStats;
      state.stats = [...state.stats];
    }
    else{
      state.stats = [...state.stats, domainStats]
    }
  }
};

import { IMessagesState } from "@/store/types/state";
import { messagesMutationTypes } from "@/store/types/mutations";
import { IMessage, INotificationMessage } from "@/types/Message";
import { IUser } from "@/types/User";

export const mutations = {
  [messagesMutationTypes.SET_MESSAGES](state: IMessagesState, messages: IMessage[]) {
    state.messages = messages;
  },
  [messagesMutationTypes.SET_CHAT_USERS](state: IMessagesState, users: IUser[]) {
    state.users = users;
  },
  [messagesMutationTypes.PUSH_USER](state: IMessagesState, user: IUser) {
    if(user && (!state.users || !state.users?.find(elm => elm.id == user.id))){
      state.users = [user,...state.users];
    }
  },
  [messagesMutationTypes.PUSH_MESSAGE](state: IMessagesState, message: IMessage) {
    state.messages = [...state.messages, message];
  },
  [messagesMutationTypes.SET_OPEN_STATUS](state: IMessagesState, isOpen: Boolean) {
    state.isChatOpen = isOpen;
  },
  [messagesMutationTypes.SET_ACTIVE_USER](state: IMessagesState, user: IUser) {
    state.activeUser = user;
  },
  [messagesMutationTypes.INC_NOTIFICATIONS_COUNT](state: IMessagesState){
    state.notificationsCount += 1;
  },
  [messagesMutationTypes.SET_NOTIFICATIONS_COUNT](state: IMessagesState, count: number){
    state.notificationsCount = count;
  },
  [messagesMutationTypes.PUSH_NOTIFICATION_MESSAGE](state: IMessagesState, notificationMessage: INotificationMessage){
    state.notificationsMessages = [...state.notificationsMessages, notificationMessage];
  },
  [messagesMutationTypes.SET_NOTIFICATIONS_MESSAGES](state: IMessagesState, notificationsMessages: INotificationMessage[]){
    state.notificationsMessages = notificationsMessages;
  }
};

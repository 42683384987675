import FileService from "@/services/files.service";
import { IFilesState, IRootState } from "@/store/types/state";
import { IFileResponse } from "@/types/File";
import { ActionContext } from "vuex";
import axios, { AxiosResponse } from "axios";
const fileService = new FileService();

export const actions = {
  /*
    Register user action
  */
  upload: async (
    { commit }: ActionContext<IFilesState,IRootState>,
    files: FileList
  ): Promise<IFileResponse[]> => {
    return fileService.upload(files).then((files:IFileResponse[]) => {
      return Promise.resolve(files);
    });
  },
  download: async (
    { commit }: ActionContext<IFilesState,IRootState>,
    url: string
  ): Promise<Blob> => {
    return axios.get(url, { responseType: 'blob' }).then( ({data}: AxiosResponse) => {
      const blob = new Blob([data], { type: data.type });
      return blob;
    });
  }
};

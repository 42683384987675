import { INotificationsState, IRootState } from "@/store/types/state";
import { ActionContext } from "vuex";
import { notificationsMutationTypes } from "@/store/types/mutations";
import NotificationsService from "@/services/notificatoins.service";
import { INotification, IResultNotifications } from "@/types/Notification";
const notificationsService = new NotificationsService();


export const actions = {
  /*
    Get notifications
  */
  getNotifications: async (
    { state, commit }: ActionContext<INotificationsState,IRootState>,
  ): Promise<Boolean> => {
    const page = state.notifications?.meta ? state.notifications.meta.currentPage + 1:1;
    const totalPages = state.notifications?.meta ? state.notifications.meta?.totalPages:1;
    if(page <= totalPages){
      return notificationsService.getNotifications(page).then((notifications:IResultNotifications) => {
        commit(notificationsMutationTypes.SET_NOTIFICATIONS, notifications);
        return true;
      });
    }
    return Promise.resolve(false);
  },
  getUnreadNotificationsCount: async (
    { state, commit }: ActionContext<INotificationsState,IRootState>,
  ):Promise<void> => {
    return notificationsService.getUnreadNotifications().then(({meta}:IResultNotifications) => {
      commit(notificationsMutationTypes.SET_NOTIFICATIONS_COUNT, meta?.totalItems);
    });
  },
  updateReadStatus: async (
    { state, commit }: ActionContext<INotificationsState,IRootState>,
    notificationId:string
  ):Promise<void> => {
    return notificationsService.updateReadStatus(notificationId).then((notification:INotification) => {
      commit(notificationsMutationTypes.UPDATE_READ_STATUS, notification);
      commit(notificationsMutationTypes.CHANGE_NOTIFICATIONS_COUNT,false);
    });
  }
};

  import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
/* Global components */
import AtomIcon from "@/components/atoms/AtomIcon.vue";
import AtomLink from "@/components/atoms/AtomLink.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";
import AtomHeading from "@/components/atoms/AtomHeading.vue";
import AtomImage from "@/components/atoms/AtomImage.vue";
import AtomInput from "@/components/atoms/AtomInput.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'element-plus/dist/index.css';
import { MotionPlugin } from '@vueuse/motion';

import Socketio from "@/plugins/Socket.io";
import authHeader from "./services/helpers/authHeader";

const app = createApp(App);

app.directive('click-outside', {
  mounted(el:any, binding:any, vnode:any) {
    el.clickOutsideEvent = function(event:any) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el:any) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

/* Third party plugins */
app.use(store);
app.use(router);
app.use(Socketio, {
  connection: process.env.NODE_ENV == 'development' ? 'http://localhost:3000':'https://api.etachawor.com',
  options: {
    autoConnect: false, 
    extraHeaders: authHeader()
  },
});
app.use(MotionPlugin);

/* Registering components */
app
.component('AtomIcon', AtomIcon)
.component('AtomLink', AtomLink)
.component('AtomInput', AtomInput)
.component('AtomHeading', AtomHeading)
.component('AtomButton', AtomButton)
.component('AtomImage', AtomImage)
.component('Carousel', Carousel)
.component('Slide', Slide)
.component('Pagination', Pagination)
.component('Navigation', Navigation)
.component('QuillEditor', QuillEditor)


app.mount("#app");

import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import authHeader from "@/services/helpers/authHeader";
import { ICreatePhase, IPhase, IUpdatePhase } from "@/types/Phase";
import { IResult } from "@/types/Result";
export default class PhasesService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/phases";

  public async getAll(page:number): Promise <IResult> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async create(phase:ICreatePhase): Promise <IPhase> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}`,
      data:phase,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async update(updatedPhase:{payload:IUpdatePhase, id:string}): Promise <IPhase> {
    const {payload, id} = updatedPhase;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${id}`,
      data:payload,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async delete(id:string): Promise <IPhase> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${id}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

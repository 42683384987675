import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import { ICreateComment, ICreatePost, IPost, IUpdatePost } from "@/types/Post";
import authHeader from "./helpers/authHeader";
import { IResult } from "@/types/Result";
export default class PostService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/content";

  public async create(post: ICreatePost): Promise <IPost> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}/posts`,
      data:post,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async update(post: IUpdatePost): Promise <IPost> {
    const {id, ...newPost} = post;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${id}`,
      data:newPost,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getByCommunityName(communityName: string, page:number): Promise <IResult> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/posts/community/${communityName}?page=${page}&limit=10`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getById(postId: string): Promise <IPost> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/${postId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getByUser(userId: string) {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/posts/user/${userId}?limit=5`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    })
  }
  public async delete(postId: string) {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${postId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    })
  }
  public async createComment(comment: ICreateComment): Promise <IPost> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}/comment`,
      data: comment,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getComments(postId: string, page:number): Promise <IResult> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/posts/${postId}/comments?limit=5&page=${page}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

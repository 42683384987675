import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import authHeader from "@/services/helpers/authHeader";
import { DomainEnum, IResultLogs, IStat } from "@/types/Activity";
export default class ActivitiesService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/activity-logs";

  public async getAll(page:number): Promise <IResultLogs> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/all/?page=${page}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getStats(domain:DomainEnum, format:string): Promise <IStat[]> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/stats?domain=${domain}&format=${format}&action=add`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

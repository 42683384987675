import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import { IArticle, ICreateArticle, IUpdateArticle } from "@/types/New";
import authHeader from "@/services/helpers/authHeader";
export default class NewsService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/news";

  public async getAll(payload:{page?:number, filter?:string}): Promise <IArticle[]> {
    const {page, filter} = payload;
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}?limit=5&page=${page}&${filter}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getPublicAdvocacies(page:number): Promise <IArticle[]> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}?limit=5&page=${page}&filter.isAdvocacy=true`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getById(articleId:string): Promise <IArticle> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/${articleId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async create(article:ICreateArticle): Promise <IArticle> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}/`,
      data:article,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async update(article:IUpdateArticle): Promise <IArticle> {
    const {id, ...fieldsToUpdate} = article;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${id}`,
      data:fieldsToUpdate,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async delete(articleId:string): Promise <IArticle> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${articleId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

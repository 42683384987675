<template>
  <component :is="type" :class="`heading ${className('heading')}`">
    <slot />
  </component>
</template>

<script>
import classNameMixin from "@/mixins/classNameMixin.js";
export default {
  mixins: [classNameMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.heading {
  @apply font-medium;
  &--xs {
    @apply text-sm lg:text-lg;
  }
  &--sm {
    @apply text-base;
  }
  &--md {
    @apply text-lg;
  }
  &--lg {
    @apply text-lg md:text-xl;
  }
  &--xl {
    @apply text-lg md:text-xl lg:text-2xl;
  }
  &--2xl {
    @apply text-xl md:text-2xl lg:text-3xl;
  }
}
</style>

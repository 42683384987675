import { IAdvocaciesState } from "@/store/types/state";
import { advocaciesMutationTypes } from "@/store/types/mutations";
import { IAdvocacy, IResultAdvocacies } from "@/types/Advocacy";

export const mutations = {
  [advocaciesMutationTypes.SET_ADVOCACIES](state: IAdvocaciesState, advocacies: IResultAdvocacies) {
    state.advocacies = advocacies;
  },
  [advocaciesMutationTypes.PUSH_ADVOCACY](state: IAdvocaciesState, advocacy: IAdvocacy) {
    state.advocacies?.data.push(advocacy);
  },
  [advocaciesMutationTypes.DELETE_ADVOCACY](state: IAdvocaciesState, advocacyId: string) {
    state.advocacies = {
      meta:(state.advocacies as IResultAdvocacies).meta,
      data:(state.advocacies as IResultAdvocacies).data.filter(advocacy => advocacy.id != advocacyId)
    }
  },
};

import { IPostsState, IRootState } from "@/store/types/state";

export const getters = {
  getById: (state:IPostsState, getters:any, rootState:IRootState) => (postId:string) => {
    const post = state.posts.find(elm => elm.communityName == rootState.communities.currentCommunityName)?.
    posts.find(post => post.id == postId);
    return post ? post:null;
  },
  getByCommunityName: (state:IPostsState) => (communityName: string) => {
    const posts = state.posts.find(elm => elm.communityName == communityName);
    return posts ? posts.posts:[];
  }
};

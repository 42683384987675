import { IPostsState } from "@/store/types/state";
import { postsMutationTypes } from "@/store/types/mutations";
import { IComment, IResultPosts, IPost, IResultComments } from "@/types/Post";
import { ICreateReaction } from "@/types/Reaction";
import { comment } from "postcss";

export const mutations = {
  [postsMutationTypes.SET_LOADING](state:IPostsState, isLoading:Boolean){
    state.loading = isLoading;
  },
  [postsMutationTypes.SET_POSTS](state: IPostsState, payload: IResultPosts) {
    const communityPosts = state.posts.find(elm => elm.communityName == payload.communityName);
    if(!communityPosts?.meta){
      state.posts = [payload, ...state.posts];
    }
    else{
      state.posts = (state.posts as IResultPosts[]).map((elm:IResultPosts) => {
        if(elm.communityName == payload.communityName){
          elm.posts = [...elm.posts, ...payload.posts];
          elm.meta = payload.meta;
        }
        return elm;
      });
    }
  },
  [postsMutationTypes.SET_USER_POSTS](state: IPostsState, payload:IResultPosts) {
    state.userPosts = payload;
  },
  [postsMutationTypes.PUSH_POST](state: IPostsState, payload:{post: IPost, communityName:string}) {
    const {post, communityName} = payload;
    // Verify if community posts exists
    const communityPosts = (state.posts as IResultPosts[]).find((elm:IResultPosts) => elm.communityName == communityName);
    if(communityPosts){
      state.posts = (state.posts as IResultPosts[]).map((elm:IResultPosts) => {
        if(elm.communityName == communityName){
          elm.posts = [post, ...elm.posts];
        }
        return elm;
      });
    }
    else{
      state.posts = [...state.posts, {
        communityName,
        posts:[post]
      } as IResultPosts]
    }
  },
  [postsMutationTypes.UPDATE_POST](state: IPostsState, payload:{updatedPost: IPost,communityName:string}) {
    const {updatedPost, communityName} = payload;
    state.posts = (state.posts as IResultPosts[]).map((elm:IResultPosts) => {
      if(elm.communityName == communityName){
        elm.posts = (elm.posts as IPost[]).map( post => {
          if(post.id == updatedPost.id){
            Object.assign(post, updatedPost);
          }
          return post;
        });
      }
      return elm;
    });
  },
  [postsMutationTypes.DELETE_POST](state: IPostsState, payload:{postId: string,communityName:string}){
    const  {postId, communityName } = payload;
    state.posts = (state.posts as IResultPosts[]).map((elm:IResultPosts) => {
      if(elm.communityName == communityName){
        elm.posts = elm.posts.filter((post:IPost) => post.id != postId);
      }
      return elm;
    });
  },
  [postsMutationTypes.SET_COMMENTS](state: IPostsState, payload:{comments:IResultComments,push:Boolean}) {
    const {comments, push} = payload;
      if(!push){
        state.comments = comments;
      }
      else{
        Object.assign((state.comments as IResultComments),
          {
            data:[...(state.comments as IResultComments).data, ...comments.data],
            meta:comments.meta
          }
        )
      }
  },
  [postsMutationTypes.PUSH_COMMENT](state: IPostsState, comment: IComment) {
    if(state.comments){
      Object.assign(state.comments, 
        {
          data:[comment, ...state.comments.data as IComment[]],
        }
      )
    }
    else{
      state.comments = {
          data:[comment]
      }
    }
  },
  [postsMutationTypes.UPDATE_COMMENT](state: IPostsState, updatedComment: IComment) {
    Object.assign((state.comments as IResultComments), {
        data: (state.comments?.data as IComment[]).map((elm:IComment) => {
          if(elm.id == updatedComment.id){
            Object.assign(elm,updatedComment);
          }
          return elm;
        }),
      }
    )
  },
  [postsMutationTypes.DELETE_COMMENT](state: IPostsState, commentId:string) {
    Object.assign((state.comments as IResultComments),
      {
        data: (state.comments?.data as IComment[]).filter(elm => elm.id != commentId)
      }
    );
  },
  [postsMutationTypes.SET_COMMENTS_COUNT](state: IPostsState, payload:{postId:string,communityName:string,mode:string}){
    const {postId, communityName, mode} = payload;
    state.posts = (state.posts as IResultPosts[]).map((elm:IResultPosts) => {
      if(elm.communityName == communityName){
        const index = (elm.posts as IPost[]).findIndex((elm:IPost) => elm.id == postId);
        mode == 'inc' ? elm.posts[index].commentsCount++: elm.posts[index].commentsCount--;
      }
      return elm;
    })
  },
  [postsMutationTypes.UPDATE_REACTION](state: IPostsState, payload:{reaction:ICreateReaction,communityName:string,remove?:Boolean}){
    const {reaction, communityName, remove} = payload;
    state.posts = (state.posts as IResultPosts[]).map((elm:IResultPosts) => {
      if(elm.communityName == communityName){
        elm.posts = (elm.posts as IPost[]).map( post => {
          if(post.id == reaction.content){
            if(reaction.type == 'like'){
              if(!remove){
                post.reactions.likes += 1;
                if(post.reactions.currentUserReaction == 'dislike') post.reactions.dislikes -= 1;
                post.reactions.currentUserReaction = 'like';
              }
              else{
                post.reactions.likes -= 1;
                post.reactions.currentUserReaction = null;
              }
            }
            else{
              if(!remove){
                if(post.reactions.currentUserReaction == 'like') post.reactions.likes -= 1;
                post.reactions.dislikes +=1;
                post.reactions.currentUserReaction = 'dislike';
              }
              else{
                post.reactions.dislikes -= 1;
                post.reactions.currentUserReaction = null;
              }

            }
          }
          return post;
        });
      }
      return elm;
    });
  }
};

<template>
  <nav :class="`nav ${isOpen ? 'h-screen' : ''}`">
    <div class="nav__container">
      <div class="nav__brand">
        <router-link :to="{ name: 'ForumHome' }">
          <img
            :src="require('@/assets/images/logo.png')"
            alt=""
            class="nav__brand-logo"
          />
        </router-link>
        <atom-icon
          class="nav__brand-hamburger"
          :icon="icon"
          @click="isOpen = !isOpen"
        ></atom-icon>
      </div>
      <!-- <ul class="nav__list">
        <li class="nav__list-item">
          <a href="" class="nav__list-item-link"> Explorer tachawor</a>
        </li>
      </ul> -->
      <div class="nav__user" v-if="currentUser">
        <router-link :to="{name:'Home'}">
          <atom-icon class="nav__user-icon" icon="home-3" />
        </router-link>
        <div class="relative">
          <span v-if="newNotificationsCount > 0" class="nav__user-notification">
            {{newNotificationsCount}}
          </span>
          <atom-icon @click.prevent = "isNotificationsOpen = !isNotificationsOpen" class="nav__user-icon" icon="notification-3" />
          <block-notifications-card
            v-if="isNotificationsOpen"
            @close="isNotificationsOpen = false"
          />
        </div>
        <!-- <div class="relative">
          <span v-if="newMessagesCount > 0" class="nav__user-notification">
            {{newMessagesCount}}
          </span>
          <atom-icon @click.prevent="openChatWindow" class="nav__user-icon" icon="message-2" />
        </div> -->
        <div class="nav__user-menu">
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton
                class="
                  max-w-xs
                  bg-white
                  flex
                  gap-1
                  items-center
                  text-sm
                  rounded-full
                  focus:outline-none
                "
              >
                <span class="sr-only">Open user menu</span>
                <atom-image
                  v-if="currentUser.avatar"
                  :src="imageUrl(currentUser.avatar)"
                  variant="rounded sm"
                  class="nav__user-menu-avatar"
                >
                </atom-image>
                <span v-else class="nav__user-menu-avatar-placeholder">{{
                  currentUser.firstname.slice(0, 1) +
                  currentUser.lastname.slice(0, 1)
                }}</span>
                <span class="nav__user-menu-text">{{
                  currentUser.firstname + " " + currentUser.lastname
                }}</span>
                <atom-icon class="nav__user-menu-icon" icon="arrow-down-s">
                </atom-icon>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="
                  origin-top-right
                  absolute
                  right-0
                  mt-2
                  w-48
                  rounded-md
                  shadow-lg
                  py-1
                  bg-white
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
              >
                <MenuItem
                  v-for="item in userNavigation"
                  :key="item.name"
                  v-slot="{ active }"
                >
                  <router-link
                    :to="item.route"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    >{{ item.name }}</router-link
                  >
                </MenuItem>
                <MenuItem
                  v-slot ="{ active }"
                >
                  <a
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    @click.prevent="logOut"
                    >
                    Déconnexion
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <!-- Mobile styles -->
      <ul :class="`nav__list-mobile ${!isOpen ? 'hidden' : ''}`">
        <li class="nav__list-mobile-item">
          <a href="" class="nav__list-mobile-item-link"> Explore tachawor</a>
        </li>
      </ul>
      <ul :class="`nav__cta-mobile ${!isOpen ? 'hidden' : ''}`"></ul>
    </div>
  </nav>
  <molecule-chat-window
    v-if="isChatOpen"
  />
</template>

<script setup>
import { ref, computed, inject, onMounted } from "vue";
import { useAuth } from "@/composables/auth";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import BlockChatContacts from "@/components/blocks/BlockChatContacts.vue";
import MoleculeChatWindow from "@/components/molecules/MoleculeChatWindow.vue";
import BlockNotificationsCard from "@/components/blocks/BlockNotificationsCard.vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
const { currentUser } = useAuth();
const {commit, state, dispatch} = useStore();
const router = useRouter();
const isOpen = ref(false);
const isChatOpen = computed(() => state.messages.isChatOpen );
const chatUsers = computed( () => state.messages.users);
const chatNotificationsMessages = computed( () => state.messages.notificationsMessages);
const isNotificationsOpen = ref(false);
const newMessagesCount = computed( () => state.messages.notificationsCount);
const newNotificationsCount = computed( () => state.notifications.unreadNotificationsCount);
import notificationSound from "@/assets/sounds/notification.mp3";
import {messagesMutationTypes, authMutationTypes, notificationsMutationTypes} from "@/store/types/mutations";
const socket = inject('socket');
const icon = computed(() => {
  return !isOpen.value ? "menu" : "close";
});
let userNavigation = [
  { name: "Profil", route: { name: "Profile" } },
  { name: "Page d'accueil", route: { name: "Home" } },
];
if(currentUser.value.role == 'admin'){
  userNavigation = [{name:"Tableau de bord", route: {name: "DashboardHome"}},...userNavigation];
}

const logOut = () => {
  commit(`auth/${authMutationTypes.SET_CURRENT_USER}`,null);
  commit(`auth/${authMutationTypes.SET_LOGGED_IN}`,false);
  router.push({name:'Home'});
}
const imageUrl = inject('imageUrl');
const openChatWindow = async () => {
  if(chatUsers.value.length) {
    commit(`messages/${messagesMutationTypes.SET_ACTIVE_USER}`, chatUsers.value[0]);
    commit(`messages/${messagesMutationTypes.SET_MESSAGES}`, []);
    dispatch('messages/getMessagesWith', chatUsers.value[0].id);
  }
  commit(`messages/${messagesMutationTypes.SET_OPEN_STATUS}`, true);
}
onMounted(async () => {
  // Get notifications count 
  await dispatch('notifications/getUnreadNotificationsCount');
  await dispatch('messages/getUnreadMessages');
  await dispatch('messages/getChatUsers');
  socket.connect();
  console.log("laaaa");
  socket.on('notification', (notification) => {
    if(notification.sender.id != currentUser.value.userId){
      commit(`notifications/${notificationsMutationTypes.PUSH_NOTIFICATION}`,notification);
      commit(`notifications/${notificationsMutationTypes.CHANGE_NOTIFICATIONS_COUNT}`, true);
      const audio = new Audio(notificationSound);
      audio.play();
    }
  });
  socket.on('receiveMessage', (data) => {
    const users = [data.sender, ...chatUsers.value.filter(item => item.id !== data.sender.id)];
    commit(`messages/${messagesMutationTypes.SET_CHAT_USERS}`, users);

    if(state.messages.activeUser?.id === data.sender.id) {
      commit(`messages/${messagesMutationTypes.PUSH_MESSAGE}`, data);
    }

    if(!isSelfMessage(data)) {
      if (!isChatOpen.value || (isChatOpen.value && !document.hasFocus())){
        commit(`messages/${messagesMutationTypes.INC_NOTIFICATIONS_COUNT}`);
      }

      if(!isChatOpen.value) {
        addChatNotificationMessage(data);
      }

      const audio = new Audio(notificationSound);
      audio.play();
    }
  });
});

const isSelfMessage = (message) => {
  return message.sender.id === state.auth.currentUser.userId;
}

const newMessageFromAnotherUser = (message) => {
  return state.messages.activeUser && message.sender.id !== state.messages.activeUser.id;
}

const addChatNotificationMessage = (data) => {
  const maxChatNotificationsItemsNbr = 6;

  const chatNotificationData = {
      id: data.id,
      message: data.messageBody,
      date: data.date,
      avatar: data.sender.avatar,
      userId: data.sender.id,
      firstname: data.sender.firstname,
      lastname: data.sender.lastname
  }

  if(chatNotificationsMessages.value.length === maxChatNotificationsItemsNbr) {
      commit(`messages/${messagesMutationTypes.SET_NOTIFICATIONS_MESSAGES}`, chatNotificationsMessages.value.slice(1));
  }
  commit(`messages/${messagesMutationTypes.PUSH_NOTIFICATION_MESSAGE}`, chatNotificationData);
}
</script>

<style lang="scss" scoped>
.nav {
  @apply w-full justify-between items-center bg-white py-7 
    md:h-auto;
  &__container {
    @apply container mx-auto px-4 
      lg:flex lg:justify-between lg:items-center;
  }
  &__brand {
    @apply flex justify-between;
    &-logo {
      @apply w-48;
    }
    &-hamburger {
      @apply w-4 cursor-pointer 
        lg:hidden;
    }
  }
  &__list {
    @apply hidden lg:block flex-1;
    &-item {
      @apply inline-block mx-3 font-medium;
      &--optional {
        @apply lg:hidden xl:inline-block;
      }
    }
  }
  &__user {
    @apply hidden lg:flex items-center gap-7;
    &-item {
      &-link {
        @apply text-sm py-1 px-4 text-center font-medium shadow-md;
      }
    }
    &-menu {
      @apply flex items-center gap-1 cursor-pointer;
      &-avatar {
        &-placeholder {
          @apply h-5 w-5 bg-primary text-white flex items-center justify-center p-4 rounded-full
          text-sm font-bold;
        }
      }
      &-text {
        @apply font-medium;
      }
      &-icon {
      }
    }
    &-notification{
      @apply rounded-full h-3 w-3 bg-red-500 
      absolute left-[-6px] top-[-6px] 
      text-white text-[10px] font-semibold
      flex justify-center items-center;
    }
    &-icon {
      @apply cursor-pointer;
    }
  }
  &__list-mobile {
    @apply mt-9 lg:hidden;
    &-item {
      &-link {
        @apply text-lg py-2 inline-block font-medium;
      }
    }
  }
  &__cta-mobile {
    @apply mt-7 lg:hidden;
    &-item {
      &-link {
        @apply text-lg py-2 w-60 text-center inline-block font-medium rounded-md shadow-md;
        &:first-of-type {
          @apply mb-5;
        }
      }
    }
  }
  &__hamburger-icon {
    @apply w-10;
  }
}
</style>

<template>
    <div class="chat-notification-container">
        <transition-group name="fade">
            <molecule-chat-notification-item
                v-for="(chatNotification, index) in chatNotificationsMessages"
                :key="index"
                class="chat-notification-container__item"
                :item="chatNotification"
                @closeItem="closeItem"
                @itemClicked="openMessageInChat"
            />
        </transition-group>
    </div>
</template>

<script setup>

// Imports
import MoleculeChatNotificationItem from "@/components/molecules/MoleculeChatNotificationItem.vue";
import {useStore} from "vuex";
import { watch, ref, computed } from 'vue';
import {messagesMutationTypes} from "@/store/types/mutations";

// Data
const { state, commit, dispatch } = useStore();
const singleNotificationDisplayTimeInMilliseconds = 5000;

// Computed
const chatNotificationsMessages = computed(() => state.messages.notificationsMessages);
const chatIsOpen = computed(() => state.messages.isChatOpen);
const chatUsers = computed(() => state.messages.users);

// watch
watch(chatNotificationsMessages, (newVal, _oldVal) => {
    if(!chatIsOpen.value && newVal.length) {
        const lastReceivedNotificationMessage = newVal[Object.keys(newVal).pop()];

        setTimeout(() => {
            closeItem(lastReceivedNotificationMessage.id);
        }, singleNotificationDisplayTimeInMilliseconds);
    }
},
{deep: true}
);
watch(chatIsOpen, (newVal, _oldVal) => {
    if(newVal) {
        commit(`messages/${messagesMutationTypes.SET_NOTIFICATIONS_MESSAGES}`, []);
    }
});

const closeItem = (itemId) => {
    commit(`messages/${messagesMutationTypes.SET_NOTIFICATIONS_MESSAGES}`, chatNotificationsMessages.value.filter(item => item.id !== itemId));
}
const openMessageInChat = async (itemId) => {
    const messageToOpen = chatNotificationsMessages.value.filter(item => item.id === itemId)[0];
    let selectedUser = chatUsers.value.filter(item => item.id === messageToOpen.userId)[0];
    if(! selectedUser) {
        await dispatch('messages/getChatUsers');
        selectedUser = chatUsers.value.filter(item => item.id === messageToOpen.userId)[0];
    }
    commit(`messages/${messagesMutationTypes.SET_ACTIVE_USER}`, selectedUser);
    dispatch('messages/getMessagesWith', selectedUser?.id);
    commit(`messages/${messagesMutationTypes.SET_OPEN_STATUS}`, true);
}
</script>

<style lang="scss" scoped>
.chat-notification-container {
    @apply
        px-2 py-3
        w-auto h-auto
        fixed bottom-0 right-0
        flex flex-col gap-1
        box-content
    ;
    &__item {
        @apply 
            w-96 h-auto
        ;
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
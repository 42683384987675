<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-white
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <router-link :to="{name:'ForumHome'}">
                  <img
                    :src="require('@/assets/images/logo.png')"
                    alt=""
                    class="w-48"
                  />
              </router-link>
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'mr-4 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="
          flex flex-col flex-grow
          border-r border-gray-200
          pt-5
          bg-white
          overflow-y-auto
        "
      >
        <div class="flex items-center flex-shrink-0 px-4">
          <router-link :to="{name:'ForumHome'}">
            <img
              :src="require('@/assets/images/logo.png')"
              alt="E-tachawor Logo"
              class="w-48"
            />
          </router-link>
        </div>
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="{ name: item.pageName }"
              :class="[
                item.current
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
              ]"
            >
              <component
                :is="item.icon"
                :class="[
                  item.current
                    ? 'text-gray-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  'mr-3 flex-shrink-0 h-6 w-6',
                ]"
                aria-hidden="true"
              />
              {{ item.name }}
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1 h-screen">
      <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="
            px-4
            border-r border-gray-200
            text-gray-500
            focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-end">
          <!-- <div class="flex-1 flex">
            <form class="w-full flex md:ml-0" action="#" method="GET">
              <label for="search-field" class="sr-only">Chercher</label>
              <div
                class="relative w-full text-gray-400 focus-within:text-gray-600"
              >
                <div
                  class="
                    absolute
                    inset-y-0
                    left-0
                    flex
                    items-center
                    pointer-events-none
                  "
                >
                  <SearchIcon class="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  class="
                    block
                    w-full
                    h-full
                    pl-8
                    pr-3
                    py-2
                    border-transparent
                    text-gray-900
                    placeholder-gray-500
                    focus:outline-none
                    focus:placeholder-gray-400
                    focus:ring-0
                    focus:border-transparent
                    sm:text-sm
                  "
                  placeholder="Chercher"
                  type="search"
                  name="search"
                />
              </div>
            </form>
          </div> -->
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    bg-white
                    flex
                    items-center
                    text-sm
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-primary
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <atom-image
                    v-if="currentUser.avatar"
                    :src="imageUrl(currentUser.avatar)"
                    variant="rounded sm"
                  >
                  </atom-image>
                  <span v-else class="h-5 w-5 bg-primary text-white flex items-center justify-center p-4 rounded-full
          text-sm font-bold">{{
                    currentUser.firstname.slice(0, 1) +
                    currentUser.lastname.slice(0, 1)
                  }}</span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link
                      :to="item.route"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >{{ item.name }}</router-link
                    >
                  </MenuItem>
                  <MenuItem
                  v-slot ="{ active }"
                   >
                  <a
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    @click.prevent="logOut"
                    >
                    Déconnexion
                  </a>
                </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <main class="flex-1 bg-light">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">
              Tableau de bord
            </h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <router-view />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import {useAuth} from "@/composables/auth";
import { useRoute, useRouter } from "vue-router";
import {useStore} from "vuex";
const {commit} = useStore();
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ClipboardCheckIcon,
  UserGroupIcon,
  NewspaperIcon,
  LightBulbIcon,
  PencilAltIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  CalendarIcon,
  DocumentIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import {authMutationTypes} from "@/store/types/mutations";
const { currentUser } = useAuth();

const userNavigation = [
  { name: "Page d'accueil", route: { name: "Home" } },
  { name: "Profile", route: { name: "Profile" } },
];

const sidebarOpen = ref(false);
const route = useRoute();
const router = useRouter();
const navigation = ref([
  {
    name: "Tableau de bord",
    pageName: "DashboardHome",
    icon: HomeIcon,
    current: "DashboardHome" == route.name,
  },
  {
    name: "Utilisateurs",
    pageName: "DashboardUsers",
    icon: UsersIcon,
    current: "DashboardUsers" == route.name,
  },
  {
    name: "Communautés",
    pageName: "DashboardCommunities",
    icon: UserGroupIcon,
    current: "DashboardCommunities" == route.name,
  },
  {
    name: "Plaidoyers",
    pageName: "DashboardAdvocacies",
    icon: LightBulbIcon,
    current: "DashboardAdvocacies" == route.name,
  },
  {
    name: "Plaidoyers Publiés",
    pageName: "DashboardPublicAdvocacies",
    icon: LightBulbIcon,
    current: "DashboardPublicAdvocacies" == route.name,
  },
  {
    name: "Chronogramme",
    pageName: "DashboardChronogram",
    icon: CalendarIcon,
    current: "DashboardChronogram" == route.name,
  },
  {
    name: "Actualités",
    pageName: "DashboardNews",
    icon: NewspaperIcon,
    current: "DashboardNews" == route.name,
  },
  {
    name: "Digitech",
    pageName: "DashboardDocuments",
    icon: DocumentIcon,
    current: "DashboardDocuments" == route.name,
  },
  {
    name: "Requêtes d'inscription",
    pageName: "DashboardRegistrationRequests",
    icon: ClipboardCheckIcon,
    current: "DashboardRegistrationRequests" == route.name,
  },
]);
watch(
  () => route.name,
  () => {
    navigation.value = [
      {
        name: "Tableau de bord",
        pageName: "DashboardHome",
        icon: HomeIcon,
        current: "DashboardHome" == route.name,
      },
      {
        name: "Utilisateurs",
        pageName: "DashboardUsers",
        icon: UsersIcon,
        current: "DashboardUsers" == route.name,
      },
      {
        name: "Communautés",
        pageName: "DashboardCommunities",
        icon: UserGroupIcon,
        current: "DashboardCommunities" == route.name,
      },
      {
        name: "Plaidoyers",
        pageName: "DashboardAdvocacies",
        icon: LightBulbIcon,
        current: "DashboardAdvocacies" == route.name,
      },
      {
        name: "Plaidoyers Publiés",
        pageName: "DashboardPublicAdvocacies",
        icon: LightBulbIcon,
        current: "DashboardPublicAdvocacies" == route.name,
      },
      {
        name: "Chronogramme",
        pageName: "DashboardChronogram",
        icon: CalendarIcon,
        current: "DashboardChronogram" == route.name,
      },
      {
        name: "Actualités",
        pageName: "DashboardNews",
        icon: NewspaperIcon,
        current: "DashboardNews" == route.name,
      },
      {
        name: "Digitech",
        pageName: "DashboardDocuments",
        icon: DocumentIcon,
        current: "DashboardDocuments" == route.name,
      },
      {
        name: "Requêtes d'inscription",
        pageName: "DashboardRegistrationRequests",
        icon: ClipboardCheckIcon,
        current: "DashboardRegistrationRequests" == route.name,
      },
    ];
  }
);
const logOut = () => {
  commit(`auth/${authMutationTypes.SET_CURRENT_USER}`,null);
  commit(`auth/${authMutationTypes.SET_LOGGED_IN}`,false);
  router.push({name:'Home'});
}
</script>

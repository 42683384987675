import { ILoggedUser, ILoginUser, IRegisterUser, IUser } from "@/types/User";
import AuthService from "@/services/auth.service";
import { authMutationTypes } from "@/store/types/mutations";
import { IAuthState, IRootState } from "@/store/types/state";
import { ActionContext } from "vuex";
const authService = new AuthService();

export const actions = {
  /*
    Register user action
  */
  registerUser: async (
    { commit }: ActionContext<IAuthState,IRootState>,
    user: IRegisterUser
  ): Promise<IUser> => {
    return authService.register(user).then(user => {
      return Promise.resolve(user);
    });
  },
  /*
    Log in user action
  */
  loginUser: async (
    { commit }: ActionContext<IAuthState,IRootState>,
    user: ILoginUser
  ): Promise<ILoggedUser> => {
    return authService.login(user).then((user:ILoggedUser) => {
      if(user.accessToken){
        commit(authMutationTypes.SET_CURRENT_USER, user);
        commit(authMutationTypes.SET_LOGGED_IN, true);
      }
      return user;
    });
  },
  doesEmailExists: async (
    { commit }: ActionContext<IAuthState,IRootState>,
    email: string
  ): Promise<Boolean> => {
    return authService.verifyEmailAtomicity(email);
  }
};

import ActivitiesService from "@/services/activities.service";
import { activitiesMutationTypes, advocaciesMutationTypes } from "@/store/types/mutations";
import { IActivityState, IRootState } from "@/store/types/state";
import { DomainEnum, IResultLogs, IStat } from "@/types/Activity";
import { ActionContext } from "vuex";
const activitiesService = new ActivitiesService();

export const actions = {
  /*
    Get Activity Logs
  */
  getAll: async (
    { state, commit }: ActionContext<IActivityState,IRootState>,
    page: number
  ): Promise<Boolean> => {
    const totalPages = state.activityLogs?.meta ? state.activityLogs.meta.totalPages : 1;
    if(page <= totalPages){
      return activitiesService.getAll(page).then((activities:IResultLogs) => {
        commit(activitiesMutationTypes.SET_ACTIVITY_LOGS, activities)
        return Promise.resolve(true);
      });
    }
    return Promise.resolve(false);
  },
  /*
    Get Stats
  */
  getStats: async (
    { state, commit }: ActionContext<IActivityState,IRootState>,
    payload:{domain:DomainEnum, format:string}
  ): Promise<Boolean> => {
      return activitiesService.getStats(payload.domain, payload.format).then((stats:IStat[]) => {
        commit(activitiesMutationTypes.SET_STATS, {
          domain:payload.domain,stats
        })
        return Promise.resolve(true);
      });
  },
};

<template>
  <div class="notifications">
    <h2 class="notifications__heading">Notifications</h2>
    <template v-if="notifications?.length">
      <div @click.prevent="viewNotification(notification)"
        :class="`notifications__item ${notification.read ? 'notifications__item--read' : ''}`"
        v-for="(notification, index) in notifications" :key="index">
        <atom-image v-if="notification.sender.avatar" :src="notification.sender.avatar" variant="rounded sm" />
        <div v-else class="notifications__item-avatar">
          {{ notification.sender.firstname[0] + notification.sender.lastname[0] }}
        </div>
        <div class="flex flex-col gap-[4px] flex-1">
          <span class="notifications__item-text">
            {{ notification.sender.firstname + ' ' + notification.sender.lastname }}
            a {{ notification.type == 'reaction' ? 'réagi à' : 'commenté' }} votre publication
          </span>
          <span class="notifications__item-date">{{ moment(notification.date).fromNow() }}</span>
        </div>
      </div>
    </template>
    <p v-else class="notifications__placeholder">
      Vous n'avez aucune notification.
    </p>
  </div>
</template>

<script setup>
import { computed, inject, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from 'moment';
moment.locale('fr');
import { notificationsMutationTypes } from "@/store/types/mutations";
const socket = inject('socket');
const emit = defineEmits(['close']);
const { state, getters, dispatch, commit } = useStore();
const router = useRouter();
const notifications = computed(() => getters['notifications/getAll']);
(async () => {
  await dispatch('notifications/getNotifications');
})();
const viewNotification = async (notification) => {
  await dispatch('notifications/updateReadStatus', notification.id);
  emit('close');
  router.push({ name: 'ForumPost', params: { postId: notification.contentId } });
};
</script>

<style lang="scss" scoped>
.notifications {
  @apply absolute z-10 top-10 right-0 w-[310px] min-h-[300px] p-3 bg-white shadow-lg rounded-lg max-h-[600px] overflow-y-auto;

  &__heading {
    @apply text-base font-medium mb-3;
  }

  &__item {
    @apply flex gap-1 py-3 cursor-pointer;

    &:not(:last-of-type) {
      @apply border-b border-b-light;
    }

    &-avatar {
      @apply w-8 h-8 rounded-full bg-primary text-white text-sm font-bold uppercase flex justify-center items-center;
    }

    &-text {
      @apply text-sm font-medium;
    }

    &-date {
      @apply text-xs text-gray-300;
    }

    &--read {
      .notifications__item {
        &-text {
          @apply font-normal text-black/70;
        }
      }
    }
  }

  &__placeholder {
    @apply text-gray-300 text-sm;
  }
}</style>
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import ForumLayout from "@/layouts/ForumLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";

const lazyLoad = (viewPath:string) => {
  return () => import(`@/views/${viewPath}.vue`)
}

const routes: Array<RouteRecordRaw> = [
  /* Public routes */
  {
    path: "/",
    component: DefaultLayout,
    redirect: to => { return {name:'Home'}},
    children:[
      {
        path: "home",
        name: "Home",
        component: lazyLoad('HomePage'),
        meta: {
          navbar: true
        }
      },
      {
        path: "data-tools/",
        name: "DataAndTools",
        component: lazyLoad('DataAndToolsPage')
      },
      {
        path: "data-tools/:institution?",
        name: "SingleDataAndTools",
        component: lazyLoad('SingleDataAndToolsPage')
      },
      {
        path: "explore",
        name: "Explore",
        component: lazyLoad('ExplorePage')
      },
      {
        path: "coalition",
        name: "Coalition",
        component: lazyLoad('CoalitionPage')
      },
      {
        path: "vision",
        name: "Vision",
        component: lazyLoad('VisionPage')
      },
      {
        path: "about",
        name: "About",
        component: lazyLoad('AboutPage')
      },
      {
        path: "contact",
        name: "Contact",
        component: lazyLoad('ContactPage')
      },
      {
        path: "news/",
        name: "News",
        component: lazyLoad('NewsPage')
      },
      {
        path: "advocacies/",
        name: "Advocacies",
        component: lazyLoad('AdvocaciesPage')
      },
      {
        path: "news/:articleId?",
        name: "Article",
        component: lazyLoad('NewPage')
      },
    ]
  },
  {
    path: "/auth",
    component: AuthLayout,
    redirect: to => {return {name:'Login'}},
    children:[
      {
        path: "login",
        name: "Login",
        component: lazyLoad('LoginPage'),
        meta:{
          requires:'guest'
        }
      },
      {
        path: "register",
        name: "Register",
        component: lazyLoad('RegisterPage'),
        meta:{
          requires:'guest'
        }
      },
      {
        path: "reset-password",
        name: "RequestResetPassword",
        component: lazyLoad('RequestResetPasswordPage'),
        meta:{
          requires:'guest'
        }
      },
      {
        path: "password-reset/:token?/:userId?",
        name: "ResetPassword",
        component: lazyLoad('ResetPasswordPage'),
        meta:{
          requires:'guest'
        }
      },
      {
        path: "welcome",
        name: "Welcome",
        component: lazyLoad('WelcomePage'),
        meta:{
          requires:'guest'
        }
      },
      {
        path: "admission-quiz",
        name: "AdmissionTest",
        component: lazyLoad('AdmissionTestPage'),
        meta:{
          requires:'guest'
        }
      },
      {
        path: "congratulations",
        name: "Congratulations",
        component: lazyLoad('CongratulationsPage'),
        meta:{
          requires:'guest'
        }
      },

    ]
  },
  /* Forum routes */
  {
    path: "/forum",
    name: "ForumLayout",
    component: ForumLayout,
    redirect: to => { return {name:'ForumHome'}},
    children: [
      {   
        path: "home",
        name: "ForumHome",
        component: lazyLoad('forum/HomePage'),
        meta: {
          requires: "client",
        }
      },
      {
        path: "posts/:postId?",
        name: "ForumPost",
        component: lazyLoad('forum/PostPage'),
        meta: {
          requires: "client",
        }
      },
      {
        path: "users/:userId?/profile",
        name: "ForumUserProfile",
        component: lazyLoad('forum/UserProfilePage'),
        meta: {
          requires: "client"
        }
      },
      {
        path: "communities/:communityName",
        name: "ForumRoom",
        component: lazyLoad('forum/RoomPage'),
        meta: {
          requires: "client"
        }
      },
      {
        path: "profile",
        name: "Profile",
        component: lazyLoad('forum/ProfilePage'),
        meta: {
          requires: "client"
        }
      },
      {
        path: "profile/settings",
        name: "Profile",
        component: lazyLoad('forum/ProfileSettingsPage'),
        meta: {
          requires: "client"
        }
      },
      {
        path: "plaidoyer",
        name: "Advocacy",
        component: lazyLoad('forum/AdvocacyPage'),
        meta: {
          requires: "client"
        }
      },
    ]
  },
  {
    path:"/dashboard",
    component: DashboardLayout,
    redirect: to => { return {name:'DashboardHome'} },
    children:[
      {
        path: "home",
        name: "DashboardHome",
        component: lazyLoad('dashboard/HomePage'),
        meta: {
          requires: "admin"
        }
      },
      {
        path: "/dashboard/users",
        component: lazyLoad('dashboard/UsersPage'),
        name: "DashboardUsers",
        meta: {
          requires: "admin",
        }
      },
      {
        path: "/dashboard/news",
        component: lazyLoad('dashboard/NewsPage'),
        name: "DashboardNews",
        meta: {
          requires: "admin",
    
        }
      },
      {
        path: "/dashboard/advocacies",
        component: lazyLoad('dashboard/AdvocaciesPage'),
        name: "DashboardAdvocacies",
        meta: {
          requires: "admin",
    
        }
      },
      {
        path: "/dashboard/public-advocacies",
        component: lazyLoad('dashboard/PublicAdvocaciesPage'),
        name: "DashboardPublicAdvocacies",
        meta: {
          requires: "admin",
    
        }
      },
      {
        path: "/dashboard/chronogram",
        component: lazyLoad('dashboard/ChronogramPage'),
        name: "DashboardChronogram",
        meta: {
          requires: "admin",
    
        }
      },
      {
        path: "/dashboard/posts",
        component: lazyLoad('dashboard/PostsPage'),
        name: "DashboardPosts",
        meta: {
          requires: "admin",
        }
      },
      {
        path: "/dashboard/communities",
        component: lazyLoad('dashboard/CommunitiesPage'),
        name: "DashboardCommunities",
        meta: {
          requires: "admin",
        }
      },
      {
        path: "/dashboard/data-tools",
        component: lazyLoad('dashboard/DataToolsPage'),
        name: "DashboardDocuments",
        meta: {
          requires: "admin",
        }
      },
      {
        path: "/dashboard/registration-requests",
        component: lazyLoad('dashboard/RegistrationRequestsPage'),
        name: "DashboardRegistrationRequests",
        meta: {
          requires: "admin",
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const requires = to.meta.requires;
  const storeData = localStorage.getItem('vuex');
  const {currentUser, loggedIn} = storeData ? JSON.parse(storeData).auth:{currentUser:null, loggedIn:false};
  const role = currentUser ? currentUser.role:null;
  switch(requires){
    case 'guest':
      loggedIn ? next(from):next();
      break;
    case 'client':
      loggedIn ? next():next({name:'Login'});
      break;
    case 'admin':
      role == 'admin' ? next():next(from);
      break;
    default:
      next();
  }
});

export default router;

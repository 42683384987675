import { IMessagesState, IRootState } from "@/store/types/state";
import { ICreateMessage, IMessage } from "@/types/Message";
import { ActionContext } from "vuex";
import MessagesService from "@/services/messages.service";
import { messagesMutationTypes } from "@/store/types/mutations";
import { IUser } from "@/types/User";
const messagesService = new MessagesService();


export const actions = {
  /*
    Get chat history (Chat users)
  */
  getChatUsers: async (
    { state, commit }: ActionContext<IMessagesState,IRootState>,
  ): Promise<Boolean> => {
    return messagesService.getChatUsers().then((users:IUser[]) => {
      commit(messagesMutationTypes.SET_CHAT_USERS, users);
      if(!state.activeUser && users?.length) commit(messagesMutationTypes.SET_ACTIVE_USER, users[0]);
      return true;
    });
  },
  /*
    Get messages with a specific user
  */
  getMessagesWith: async (
    { commit }: ActionContext<IMessagesState,IRootState>,
    userId: string
  ): Promise<Boolean> => {
    return messagesService.getMessagesWith(userId).then((messages:IMessage[]) => {
      commit(messagesMutationTypes.SET_MESSAGES, messages);
      return true;
    });
  },
  /* 
    Get unread messages
  */
  getUnreadMessages: async (
    { commit }: ActionContext<IMessagesState,IRootState>
  ):Promise<Boolean> => {
    return messagesService.getUnreadMessages().then((messages:IMessage[]) => {
      return true;
    });
  }
  /* 
    Update Read
  */
};

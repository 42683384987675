import NewsService from "@/services/news.service";
import { newsMutationTypes } from "@/store/types/mutations";
import { INewsState, IRootState } from "@/store/types/state";
import { IArticle, ICreateArticle, IUpdateArticle } from "@/types/New";
import { ActionContext } from "vuex";
const newsService = new NewsService();

export const actions = {
  getAll: async (
    { commit }: ActionContext<INewsState,IRootState>, 
    payload: {page:number, filter:string}
  ): Promise<IArticle[]> => {
    return newsService.getAll(payload).then((articles:IArticle[]) => {
      if(payload.filter == "filter.isAdvocacy=false"){
        commit(newsMutationTypes.SET_ARTICLES, articles);
      }
      else{
        commit(newsMutationTypes.SET_ADVOCACIES, articles);
      }
      return articles;
    });
  },
  getById: async (
    { commit }: ActionContext<INewsState,IRootState>,
    articleId: string
  ): Promise<IArticle> => {
    return newsService.getById(articleId).then((article:IArticle) => {
      while(typeof article.content == "string"){
        article.content = JSON.parse(article.content);
      }
      commit(newsMutationTypes.PUSH_ARTICLE, article);
      return article;
    });
  },
  create: async (
    { commit }: ActionContext<INewsState,IRootState>,
    payload: {article:ICreateArticle, isAdvocacy:boolean}
  ): Promise<IArticle> => {
    return newsService.create(payload.article).then((article:IArticle) => {
      if(payload.isAdvocacy){
        commit(newsMutationTypes.PUSH_ADVOCACY, article);
      }
      else{
        commit(newsMutationTypes.PUSH_ARTICLE, article);
      }
      return article;
    });
  },
  update: async (
    { commit }: ActionContext<INewsState,IRootState>,
    payload: {article:IUpdateArticle, isAdvocacy:boolean}
  ): Promise<IArticle> => {
    return newsService.update(payload.article).then((article:IArticle) => {
      if(payload.isAdvocacy){
        commit(newsMutationTypes.UPDATE_ADVOCACY, article);
      }
      else{
        commit(newsMutationTypes.UPDATE_ARTICLE, article);
      }
      return article;
    });
  },
  delete: async (
    {commit}: ActionContext<INewsState,IRootState>,
    payload: {id:string, isAdvocacy:boolean}
  ): Promise<Boolean> => {
    return newsService.delete(payload.id).then(() => {
      if(payload.isAdvocacy){
        commit(newsMutationTypes.DELETE_ADVOCACY, payload.id);
      }
      else{
        commit(newsMutationTypes.DELETE_ARTICLE, payload.id);
      }
      return true;
    });
  }
};

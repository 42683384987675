import { INewsState } from "@/store/types/state";

export const getters = {
  getAll(state:INewsState){
    return state.news;
  },
  getById: (state:INewsState) => (articleId: string) => {
    return state.news?.data?.find(elm => elm.id == articleId);
  }
};

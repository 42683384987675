import { AxiosRequestConfig } from "axios";
import { BaseService } from "./base.service";
import axios from "axios";
import authHeader from "./helpers/authHeader";
import { IUser } from "@/types/User";
import { IMessage } from "@/types/Message";

export default class MessagesService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/messages";
  public async getChatUsers(): Promise<IUser[]> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/history`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async getMessagesWith(userId:string): Promise<IMessage[]> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}/${userId}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
  public async getUnreadMessages(): Promise<IMessage[]> {
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"GET",
        url:`${this._BASE_API_URL}`,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
}

import { INewsState } from "@/store/types/state";

export const getDefaultState = (): INewsState => {
  return {
    news:{
      data:[]
    },
    publicAdvocacies:{
      data:[]
    },
  };
};

export const state: INewsState = getDefaultState();

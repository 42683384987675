import { ICommunitiesState } from "@/store/types/state";

export const getDefaultState = (): ICommunitiesState => {
  return {
    communities:[],
    userCommunities:[],
    community:null,
    currentCommunityName:'public'
  };
};

export const state: ICommunitiesState = getDefaultState();

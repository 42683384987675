import { IPostsState } from "@/store/types/state";
export const getDefaultState = (): IPostsState => {
  return {
    posts:[],
    post:null,
    comments: null,
    loading: true,
    userPosts:null
  };
};

export const state: IPostsState = getDefaultState();

<template>
  <img :class="`img ${className('img')}`" src="https://blog.focusinfotech.com/wp-content/uploads/2017/12/default-placeholder-300x300.png"/>
</template>

<script>
import classNameMixin from "@/mixins/classNameMixin.js";
export default {
  mixins: [classNameMixin],
  props: {
    variant: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  object-fit: cover;
  &--rounded {
    @apply rounded-full;
  }
  &--xs {
    @apply w-7 h-7;
  }
  &--sm {
    @apply w-8 h-8;
  }
  &--md {
    @apply w-[83px] h-[83px];
  }
  &--lg {
    @apply w-[100px] h-[100px];
  }
}
</style>

import { IPhasesState } from "@/store/types/state";
import { phasesMutationTypes } from "@/store/types/mutations";
import { IPhase } from "@/types/Phase";

export const mutations = {
  [phasesMutationTypes.SET_PHASES](state: IPhasesState, phases: IPhase[]) {
    state.phases = phases;
  },
  [phasesMutationTypes.SET_PHASE](state: IPhasesState, payload: IPhase) {
    state.phases = (state.phases as IPhase[]).map(phase => {
      if(phase.id == payload.id) phase = payload;
      return phase;
    });
  },
  [phasesMutationTypes.PUSH_PHASE](state: IPhasesState, phase: IPhase) {
    state.phases = [...(state.phases as IPhase[]), phase];
  },
  [phasesMutationTypes.DELETE_PHASE](state: IPhasesState, phaseId: string) {
    state.phases = (state.phases as IPhase[]).filter(phase => phase.id != phaseId);
  },
};

import { AxiosRequestConfig } from "axios";import { BaseService } from "./base.service";
import axios from "axios";
import authHeader from "./helpers/authHeader";
import { IFileResponse } from "@/types/File";

export default class FileService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/files";
  public async upload(files:FileList): Promise<IFileResponse[]> {
    const formData = new FormData();
    Array.prototype.forEach.call(files, (file) => {
      formData.append('files', file);
    });
    const config: AxiosRequestConfig = this.getConfig(
      {
        method:"POST",
        url:`${this._BASE_API_URL}/upload`,
        data: formData,
        headers:authHeader()
      }
    );
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    });
  }
}

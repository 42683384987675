import { IActivityState } from "@/store/types/state";

export const getDefaultState = (): IActivityState => {
  return {
    activityLogs:null,
    stats:[]
  };
};

export const state: IActivityState = getDefaultState();

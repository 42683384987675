import { BaseService } from "./base.service";
import axios, { AxiosRequestConfig } from "axios";
import authHeader from "@/services/helpers/authHeader";
import { IResult } from "@/types/Result";
import { ICreateDocument, IDocument, IUpdateDocument } from "@/types/Document";
export default class DocumentsService extends BaseService {
  // Members
  private _BASE_API_URL: string = process.env.VUE_APP_API + "/documents";

  public async getAll(payload:{page:number,filter:string, limit: number}): Promise <IResult> {
    const {page, filter, limit} = payload;
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/?limit=${limit}&page=${page}&${filter}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async getById(id:string): Promise <IDocument> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"GET",
      url:`${this._BASE_API_URL}/${id}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async create(document:ICreateDocument): Promise <IDocument> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"POST",
      url:`${this._BASE_API_URL}/`,
      data:document,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async update(community:IUpdateDocument): Promise <IDocument> {
    const {id, ...updatedCommunity} = community;
    const config: AxiosRequestConfig = this.getConfig({
      method:"PATCH",
      url:`${this._BASE_API_URL}/${id}`,
      data:updatedCommunity,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
  public async delete(documentId:string): Promise <string> {
    const config: AxiosRequestConfig = this.getConfig({
      method:"DELETE",
      url:`${this._BASE_API_URL}/${documentId}`,
      headers:authHeader()
    });
    return axios(config).then(res => {
      return Promise.resolve(res.data);
    }) 
  }
}

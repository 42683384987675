import UsersService from "@/services/users.service";
import { IRootState, IUsersState } from "@/store/types/state";
import { IResult } from "@/types/Result";
import { IRegisterUser, IUpdateUser, IUser, ResetPasswordDto } from "@/types/User";
import { ActionContext } from "vuex";
const usersService = new UsersService();

export const actions = {
  getAll: async (
    { commit, state }: ActionContext<IUsersState,IRootState>,
    page:number
  ): Promise<IUser[]> => {
    const users = state.users;
    return usersService.getAll(page).then(({data, meta}: IResult) => {
      commit('SET_USERS', {data,meta});
      return Promise.resolve(data);
    });
  },
  getById: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    userId:string
  ): Promise<void> => {
    return usersService.getById(userId).then((user:IUser) => {
      commit('SET_USER', user);
    });
  },
  create: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    user:IRegisterUser
  ): Promise<void> => {
    return usersService.create(user).then((user:IUser) => {
      commit('PUSH_USER', user);
    });
  },
  update: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    user:IUpdateUser
  ): Promise<IUser> => {
    return usersService.update(user).then((updatedUser:IUser) => {
      commit('UPDATE_USER', updatedUser);
      return updatedUser;
    });
  },
  delete: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    userId:string
  ): Promise<IUser> => {
    return usersService.delete(userId).then((user:IUser) => {
      commit('DELETE_USER', userId);
      return Promise.resolve(user);
    });
  },
  getByStatus: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    status:string
  ): Promise<void> => {
    return usersService.getByStatus(status).then((users:IUser[]) => {
      commit('SET_USERS', users);
    });
  },
  requestResetPassword: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    email:string
  ): Promise<Boolean> => {
    return usersService.requestResetPassword(email).then((requestSent:Boolean) => {
      return Promise.resolve(requestSent);
    });
  },
  resetPassword: async (
    { commit }: ActionContext<IUsersState,IRootState>,
    payload:ResetPasswordDto
  ): Promise<Boolean> => {
    return usersService.resetPassword(payload).then((passwordReset:Boolean) => {
      return Promise.resolve(passwordReset);
    });
  },
};

import { IAuthState } from "@/store/types/state";

export const getters = {
  currentUser(state:IAuthState){
    return state.currentUser;
  },
  loggedIn(state:IAuthState){
    return state.loggedIn;
  }
};

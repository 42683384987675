<template>
  <component
    v-if="icon"
    :is="
      require(`vue-remix-icons/icons/ri-${icon}-${fill == true ? 'fill' : 'line'}.js`)
        .default
    "
    class="icon"
  />
</template>

<script>
export default {
  props: {
    icon: String,
    fill: Boolean
  }
};
</script>
<style lang="scss" scoped>
.icon {
  @apply w-6;
}
</style>

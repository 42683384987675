import { ICommunitiesState } from "@/store/types/state";

export const getters = {
  getAll: (state:ICommunitiesState) => {
    return state.communities;
  },
  getCommunity: (state:ICommunitiesState) => (communityName:string) => {
    return state.community?.name == communityName ? state.community:null;
  }
};

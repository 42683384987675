import AdvocaciesService from "@/services/advocacies.service";
import { advocaciesMutationTypes } from "@/store/types/mutations";
import { IAdvocaciesState, IRootState } from "@/store/types/state";
import { IResultAdvocacies, IAdvocacy, ICreateAdvocacy } from "@/types/Advocacy";
import { ActionContext } from "vuex";
const advocaciesService = new AdvocaciesService();

export const actions = {
  /*
    Get advocacies
  */
    getAll: async (
      { state, commit }: ActionContext<IAdvocaciesState,IRootState>,
      page: number
    ): Promise<Boolean> => {
      const totalPages = state.advocacies?.meta ? state.advocacies.meta.totalPages : 1;
      if(page <= totalPages){
        return advocaciesService.getAll(page).then((advocacies:IResultAdvocacies) => {
          commit(advocaciesMutationTypes.SET_ADVOCACIES, advocacies)
          return Promise.resolve(true);
        });
      }
      return Promise.resolve(false);
    },
    /* 
      Create Advocacy
    */
   create: async(
    { state, commit }: ActionContext<IAdvocaciesState,IRootState>,
    newAdvocacy: ICreateAdvocacy
   ): Promise<IAdvocacy> => {
      return advocaciesService.create(newAdvocacy).then((advocacy:IAdvocacy) => {
        commit(advocaciesMutationTypes.PUSH_ADVOCACY, advocacy);
        return Promise.resolve(advocacy);
      });
   },
   /* 
    Delete Advocacy
   */
    delete: async(
      { state, commit }: ActionContext<IAdvocaciesState,IRootState>,
      id: string
     ): Promise<IAdvocacy> => {
        return advocaciesService.delete(id).then((advocacy:IAdvocacy) => {
          commit(advocaciesMutationTypes.DELETE_ADVOCACY, id);
          return Promise.resolve(advocacy);
        });
     }

};

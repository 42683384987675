import { ICommunitiesState } from "@/store/types/state";
import { communitiesMutationTypes } from "@/store/types/mutations";
import { ICommunity } from "@/types/Community";

export const mutations = {
  [communitiesMutationTypes.SET_COMMUNITIES](state: ICommunitiesState, communities: ICommunity[]) {
    state.communities = communities;
  },
  [communitiesMutationTypes.SET_COMMUNITY](state: ICommunitiesState, community: ICommunity) {
    state.community = community;
  },
  [communitiesMutationTypes.SET_USER_COMMUNITIES](state: ICommunitiesState, communities: ICommunity[]) {
    state.userCommunities = communities;
  },
  [communitiesMutationTypes.PUSH_COMMUNITY](state: ICommunitiesState, community:ICommunity) {
    if(!state.communities.find(elm => elm.id == community.id)){
      state.communities = [community, ...state.communities];
    }
  },
  [communitiesMutationTypes.UPDATE_COMMUNITY](state: ICommunitiesState, updatedCommunity:ICommunity) {
    state.communities = (state.communities as ICommunity[]).map( (community:ICommunity) => {
      if(community.id == updatedCommunity.id){
        Object.assign(community, updatedCommunity);
      }
      return community;
    });
  },
  [communitiesMutationTypes.DELETE_COMMUNITY](state: ICommunitiesState, communityId: string) {
    state.communities = state.communities.filter( elm => elm.id !== communityId);
  },
  [communitiesMutationTypes.SET_CURRENT_COMMUNITY_NAME](state: ICommunitiesState, name:string) {
    state.currentCommunityName = name;
  }
};

<template>
  <div class="form__item">
    <span class="form__item-notice" v-if="required">Required *</span>
    <input class="form__item-input" :type="type" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  &__item {
    &-notice {
      @apply text-[10px] text-[#C4C4C4] inline-block mb-[3px];
    }
    &-input {
      @apply relative w-full py-2 px-4 border border-gray-100 placeholder-gray-base rounded-md;
    }
  }
}
</style>

import PhasesService from "@/services/phases.service";
import { phasesMutationTypes } from "@/store/types/mutations";
import { IPhasesState, IRootState } from "@/store/types/state";
import { ICreatePhase, IPhase, IUpdatePhase } from "@/types/Phase";
import { IResult } from "@/types/Result";
import { ActionContext } from "vuex";
import phases from ".";
const phasesService = new PhasesService();

export const actions = {
  /*
    Get phases
  */
    getAll: async (
      { state, commit }: ActionContext<IPhasesState,IRootState>,
      page: number
    ): Promise<IPhase[]> => {
        return phasesService.getAll(page).then(({data}:IResult) => {
          commit(phasesMutationTypes.SET_PHASES, data)
          return Promise.resolve(data);
        });
    },
  /* 
    Create phase
  */
   create: async (
    { state, commit }: ActionContext<IPhasesState,IRootState>,
    newPhase: ICreatePhase
   ): Promise<IPhase> => {
      return phasesService.create(newPhase).then((phase:IPhase) => {
        commit(phasesMutationTypes.PUSH_PHASE, phase);
        return Promise.resolve(phase);
      });
   },
  /* 
    Update phase
  */
    update: async (
      { state, commit }: ActionContext<IPhasesState,IRootState>,
      newPhase: {id:string, payload:IUpdatePhase} 
     ): Promise<IPhase> => {
        return phasesService.update(newPhase).then((phase:IPhase) => {
          commit(phasesMutationTypes.SET_PHASE, phase);
          return Promise.resolve(phase);
        });
     },
   /* 
    Delete phase
   */
    delete: async(
      { state, commit }: ActionContext<IPhasesState,IRootState>,
      id: string
     ): Promise<IPhase> => {
        return phasesService.delete(id).then((phase:IPhase) => {
          commit(phasesMutationTypes.DELETE_PHASE, id);
          return Promise.resolve(phase);
        });
     }

};
